import {
    Accordion,
    AccordionDetails,
    AccordionSummary,
    Box,
    Checkbox,
    FormControlLabel,
    Typography,
} from '@mui/material';
import type { SurveyQuestion } from '@spec/Survey';
import { isBooleanQuestion, isTextQuestion } from '../../../../domains/Survey';
import { StateValue } from '../../../../lib/Context';
import { FlexBox } from '../../../FlexBox';
import { ExpandMoreIcon, FilterListIcon } from '../../../Icons';

export const ResponseFilter: React.FC<{
    questions: SurveyQuestion[];
    requires: StateValue<Set<number>>;
}> = (props) => {
    const questions = props.questions.filter((q) => isTextQuestion(q) || isBooleanQuestion(q));
    if (questions.length === 0) {
        return null;
    }
    return (
        <Box mt={1}>
            <Accordion>
                <AccordionSummary expandIcon={<ExpandMoreIcon />}>
                    <FlexBox>
                        <FilterListIcon />
                        <Box ml={0.5}>記述項目への回答の有無で絞り込む</Box>
                    </FlexBox>
                </AccordionSummary>
                <AccordionDetails>
                    {questions.map((q) => (
                        <FlexBox key={q.id}>
                            <Box ml={2}>
                                <FormControlLabel
                                    control={
                                        <Checkbox
                                            checked={props.requires.value.has(q.id)}
                                            onChange={(e) => {
                                                props.requires.setValue((prev) => {
                                                    const x = new Set(prev);
                                                    if (e.target.checked) {
                                                        x.add(q.id);
                                                    } else {
                                                        x.delete(q.id);
                                                    }
                                                    return x;
                                                });
                                            }}
                                            name={q.shortTitle}
                                            size="small"
                                        />
                                    }
                                    label={
                                        <Typography variant="body2">
                                            {q.shortTitle} : {q.title}
                                        </Typography>
                                    }
                                />
                            </Box>
                        </FlexBox>
                    ))}
                </AccordionDetails>
            </Accordion>
        </Box>
    );
};
