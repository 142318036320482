import { Alert, AlertTitle, Box, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { RoutingPattern } from '../../../Routes';
import { getLatestPeriod } from '../../../domains/Survey';
import { findById } from '../../../lib/ArrayUtils';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { NoItems } from '../../NoItems';
import { SubTitle } from '../../PageTitle';
import { useSurveysContext } from '../Context';
import { SurveyApplyForm } from '../apply/ApplyForm';

export const Preview = () => {
    const { groupId } = useParams();
    const { surveyGroups, surveys } = useSurveysContext();
    const group = findById(Number(groupId), surveyGroups);
    const period = getLatestPeriod(group);
    if (period === null) {
        return (
            <Box>
                <SubTitle title="回答画面のプレビュー" />
                <NoItems>実施期間がありません</NoItems>
            </Box>
        );
    }
    return (
        <Box>
            <SubTitle title="回答画面のプレビュー" />
            <Container maxWidth="md" sx={{ mt: 2 }}>
                <Alert severity="warning">
                    <AlertTitle>この画面は表示確認用の回答画面プレビューです</AlertTitle>
                    <Typography variant="body2">
                        ここからは回答できなければ、管理権限を持っていないとアクセスもできません。この画面のURLをSlackの告知などで共有しないでください。
                    </Typography>
                </Alert>
            </Container>
            <Typography mt={2} variant="h6" textAlign="center">
                {group.name} {period.name}
            </Typography>
            {surveys
                .filter((v) => v.surveyGroupId === group.id)
                .map((v) => (
                    <SurveyApplyForm key={v.id} period={period} survey={v} setResponse={() => {}} />
                ))}
            <ActionContainer>
                <ActionButton to={RoutingPattern.surveyAdmin}>一覧に戻る</ActionButton>
            </ActionContainer>
        </Box>
    );
};
