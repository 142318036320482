import { TableCell, TableRow } from '@mui/material';
import type { MutualTouchHistory } from '@spec/Touch';
import dayjs, { type Dayjs } from 'dayjs';
import { useState } from 'react';
import { useAllTouches } from '../../queries/talent';
import { BackHandIcon } from '../Icons';
import { PageTitle } from '../PageTitle';
import { RequirePrivilege } from '../RequirePrivilege';
import { SortableTable } from '../SortableTable';
import { WaitQuery } from '../WaitLoading';

export const Touch = () => {
    return (
        <RequirePrivilege fn={(grants) => grants.manageHiTouch}>
            <PageTitle icon={BackHandIcon} title="タッチ履歴" />
            <Loader />
        </RequirePrivilege>
    );
};

type Row = {
    id: string;
    scannerName: string;
    scannerTeam: string;
    targetName: string;
    targetTeam: string;
    matchedAt: Dayjs;
};

const headers: Array<[keyof Row, string]> = [
    ['matchedAt', 'タッチ時刻'],
    ['scannerName', 'タッチした人'],
    ['scannerTeam', 'タッチした人の所属'],
    ['targetName', 'タッチされた人'],
    ['targetTeam', 'タッチされた人の所属'],
];

const convertRow = (history: MutualTouchHistory): Row => {
    const matchedAt = dayjs(history.matchedAt);
    return {
        id: `${history.matchedAt.getTime()}`,
        scannerName: history.scanner.name,
        scannerTeam: history.scanner.team,
        targetName: history.target.name,
        targetTeam: history.target.team,
        matchedAt,
    };
};

const Loader = () => {
    const maybeTouches = useAllTouches();
    return (
        <WaitQuery query={maybeTouches}>
            {({ data }) => {
                const uniqueRows = filterUniqueRows(data.map(convertRow));
                return <HistoryTable rows={uniqueRows} />;
            }}
        </WaitQuery>
    );
};

const filterUniqueRows = (rows: Row[]): Row[] => {
    const uniqueMap = new Map<string, Row>();
    rows.forEach((row) => {
        const key = `${row.scannerName}-${row.scannerTeam}-${row.targetName}-${row.targetTeam}`;
        const existingRow = uniqueMap.get(key);
        if (!existingRow || existingRow.matchedAt.isBefore(row.matchedAt)) {
            uniqueMap.set(key, row);
        }
    });
    return Array.from(uniqueMap.values());
};

const HistoryTable = (props: { rows: Row[] }) => {
    const [page, setPage] = useState(0);
    return (
        <SortableTable
            stickyHeader
            headers={headers}
            defaultSortKey="matchedAt"
            defaultSortDirection="desc"
            secondarySortKey="scannerName"
            rows={props.rows}
            pagerProps={{
                current: page,
                setPage,
                amount: props.rows.length,
                perItems: 50,
            }}
        >
            {(row) => (
                <TableRow hover>
                    <TableCell>{dayjs(row.matchedAt).format('YYYY-MM-DD HH:mm:ss')}</TableCell>
                    <TableCell>{row.scannerName}</TableCell>
                    <TableCell>{row.scannerTeam}</TableCell>
                    <TableCell>{row.targetName}</TableCell>
                    <TableCell>{row.targetTeam}</TableCell>
                </TableRow>
            )}
        </SortableTable>
    );
};
