import { Box, MenuItem, TextField } from '@mui/material';
import { Team, TeamId } from '@spec/Organization';
import { Talent } from '@spec/Talent';
import { TodoAssignment } from '@spec/Todo';
import React, { useState } from 'react';
import { findChildTeams } from '../../../../domains/Organization';
import { searchTalent } from '../../../../domains/Talent';
import { useTalentsContext } from '../../../Context';
import { FilterCondition as FilterColumn, FilterForm, FilterRow } from '../../../FilterForm';
import { FlexBox } from '../../../FlexBox';
import { HelpTip } from '../../../HelpTip';
import { MultiSelect } from '../../../MultiSelect';
import { ROOT_TEAM_ID, TeamDrill } from '../../../TeamDrill';

export interface FilterCondition {
    joinedYears: number[];
    newGraduates: NewGraduate;
    employmentStatus: string[];
    employeeCode: string;
    name: string;
    teamId: TeamId | null;
    completed: Completed;
}

type Completed = 'all' | 'completed' | 'progress';
type NewGraduate = 'all' | 'newGraduate' | 'career';

type UpdateFilterCondition = (v: Partial<FilterCondition>) => void;

export const useFilterCondition = (): [FilterCondition, UpdateFilterCondition] => {
    const [condition, setCondition] = useState<FilterCondition>({
        joinedYears: [],
        newGraduates: 'all',
        employmentStatus: [],
        employeeCode: '',
        name: '',
        teamId: ROOT_TEAM_ID,
        completed: 'all',
    });
    const updateCondition = (v: Partial<FilterCondition>) => {
        setCondition((before) => {
            return { ...before, ...v };
        });
    };
    return [condition, updateCondition];
};

export const filterTalents = (
    condition: FilterCondition,
    talents: Talent[],
    teams: Team[],
    assignments: TodoAssignment[]
): Talent[] => {
    const years = new Set(condition.joinedYears);
    const teamIds =
        condition.teamId === null ? [] : findChildTeams(condition.teamId, teams).map((v) => v.id);
    return talents
        .filter((v) => (years.size === 0 ? true : years.has(v.joinedAt.getFullYear())))
        .filter((v) => {
            switch (condition.newGraduates) {
                case 'newGraduate':
                    return v.isNewGraduate === true;
                case 'career':
                    return v.isNewGraduate === false;
            }
            return true;
        })
        .filter((v) =>
            condition.employmentStatus.length === 0
                ? true
                : condition.employmentStatus.includes(v.employment.employmentStatus)
        )
        .filter((v) =>
            condition.employeeCode === ''
                ? true
                : v.employment.employeeCode
                      .toLocaleLowerCase()
                      .startsWith(condition.employeeCode.toLocaleLowerCase())
        )
        .filter((v) => searchTalent(condition.name, v))
        .filter((v) => (condition.teamId === null ? true : teamIds.includes(v.teamId)))
        .filter((v) => {
            switch (condition.completed) {
                case 'completed':
                    return !!assignments.find((a) => a.talentId === v.id && a.completedAt !== null);
                case 'progress':
                    return !!assignments.find((a) => a.talentId === v.id && a.completedAt === null);
            }
            return true;
        });
};

const FIRST_LABEL_WIDTH = 2.5;

export const AssigneeFilterForm: React.FC<{
    condition: FilterCondition;
    updateCondition: UpdateFilterCondition;
}> = (props) => {
    const { talents } = useTalentsContext();
    const years = new Set(talents.map((v) => v.joinedAt.getFullYear()));
    const employmentStatus = new Set(talents.map((v) => v.employment.employmentStatus));
    return (
        <FilterForm dense>
            <FilterRow>
                <FilterColumn label="入社年" labelWidth={FIRST_LABEL_WIDTH}>
                    <MultiSelect
                        values={props.condition.joinedYears}
                        setItems={(v) => props.updateCondition({ joinedYears: v.map(Number) })}
                    >
                        {[...years]
                            .sort()
                            .reverse()
                            .map((v) => (
                                <MenuItem key={v} value={v}>
                                    {v}
                                </MenuItem>
                            ))}
                    </MultiSelect>
                </FilterColumn>
                <FilterColumn label="入社区分">
                    <TextField
                        select
                        value={props.condition.newGraduates}
                        onChange={(e) =>
                            props.updateCondition({ newGraduates: e.target.value as NewGraduate })
                        }
                    >
                        <MenuItem value="all">すべて</MenuItem>
                        <MenuItem value="newGraduate">新卒</MenuItem>
                        <MenuItem value="career">中途</MenuItem>
                    </TextField>
                </FilterColumn>
                <FilterColumn label="雇用形態">
                    <MultiSelect
                        values={props.condition.employmentStatus}
                        setItems={(v) => props.updateCondition({ employmentStatus: v })}
                    >
                        {[...employmentStatus].sort().map((v) => (
                            <MenuItem key={v} value={v}>
                                {v}
                            </MenuItem>
                        ))}
                    </MultiSelect>
                </FilterColumn>
                <FilterColumn
                    label={
                        <FlexBox>
                            社員区分
                            <HelpTip title="社員番号の先頭1〜2文字で検索できます" />
                        </FlexBox>
                    }
                >
                    <TextField
                        value={props.condition.employeeCode}
                        onChange={(e) => {
                            props.updateCondition({
                                employeeCode: e.target.value,
                            });
                        }}
                        sx={{ width: '3rem' }}
                        slotProps={{
                            htmlInput: { maxLength: 2 },
                        }}
                    />
                </FilterColumn>
            </FilterRow>
            <FilterRow>
                <FilterColumn label="状態" labelWidth={FIRST_LABEL_WIDTH}>
                    <TextField
                        select
                        value={props.condition.completed}
                        onChange={(e) =>
                            props.updateCondition({ completed: e.target.value as Completed })
                        }
                    >
                        <MenuItem value="all">すべて</MenuItem>
                        <MenuItem value="completed">完了済み</MenuItem>
                        <MenuItem value="progress">未完了</MenuItem>
                    </TextField>
                </FilterColumn>
                <FilterColumn flexGrow={1} label="氏名">
                    <Box flexGrow={1}>
                        <TextField
                            fullWidth
                            placeholder="氏名、ヨミガナ、英語表記やニックネームなどで部分検索できます。"
                            value={props.condition.name}
                            onChange={(e) => {
                                props.updateCondition({
                                    name: e.target.value,
                                });
                            }}
                        />
                    </Box>
                </FilterColumn>
            </FilterRow>
            <FilterRow>
                <FilterColumn label="所属" labelWidth={FIRST_LABEL_WIDTH}>
                    <TeamDrill
                        teamId={props.condition.teamId}
                        setTeamId={(teamId) => props.updateCondition({ teamId })}
                    />
                </FilterColumn>
            </FilterRow>
        </FilterForm>
    );
};
