import { DialogTitle } from '@mui/material';
import {
    isBooleanQuestion,
    isLikertQuestion,
    isSelectQuestion,
    isTextQuestion,
} from '../../../../../domains/Survey';
import { ApplicationError } from '../../../../../Errors';
import { useUpdateSurveyQuestion } from '../../../../../queries/surveyAdmin';
import { useEditQuestionContext } from '../Context';
import { BooleanForm, LikertForm, SelectForm, TextForm } from '../QuestionForm';

export const EditQuestionDialog = () => {
    const { question } = useEditQuestionContext();
    if (question.value === null) {
        throw new ApplicationError('question is not stored in the context');
    }
    const q = question.value;
    const mutation = useUpdateSurveyQuestion(question.value.id);
    return (
        <>
            <DialogTitle>「{q.title}」を編集する</DialogTitle>
            {isLikertQuestion(q) === true && <LikertForm question={q} mutation={mutation} />}
            {isBooleanQuestion(q) === true && <BooleanForm question={q} mutation={mutation} />}
            {isTextQuestion(q) === true && <TextForm question={q} mutation={mutation} />}
            {isSelectQuestion(q) === true && <SelectForm question={q} mutation={mutation} />}
        </>
    );
};
