import type { TeamId } from './Organization';
import type { TalentId } from './Talent';

export interface SurveyGroup {
    id: number;
    name: string;
    description: string;
    confirmMessage: string;
    periods: SurveyPeriod[];
}

export interface Survey {
    id: number;
    surveyGroupId: number;
    name: string;
    description: string;
    questions: SurveyQuestion[];
}

export interface ApplicableSurvey {
    group: {
        id: number;
        name: string;
        description: string;
    };
    period: {
        id: number;
        name: string;
        openedAt: Date;
        closedAt: Date;
    };
}

export interface SurveyBaseQuestion {
    id: number;
    shortTitle: string;
    title: string;
}

export interface SurveyLikertQuestion extends SurveyBaseQuestion {
    minLabel: string;
    maxLabel: string;
    minScore: number;
    maxScore: number;
}

export interface SurveyBooleanQuestion extends SurveyBaseQuestion {
    label: string;
}

export type SurveyTextQuestion = SurveyBaseQuestion;

export interface SurveySelectQuestion extends SurveyBaseQuestion {
    maxSelections: 1; // TODO: Support multiple selections
    options: string[];
}

export type SurveyQuestion =
    | SurveyLikertQuestion
    | SurveyBooleanQuestion
    | SurveyTextQuestion
    | SurveySelectQuestion;

export type EditLikertQuestionRequest = Omit<SurveyLikertQuestion, 'id'>;
export type EditBooleanQuestionRequest = Omit<SurveyBooleanQuestion, 'id'>;
export type EditTextQuestionRequest = Omit<SurveyTextQuestion, 'id'>;
export type EditSurveyQuestionRequest =
    | EditLikertQuestionRequest
    | EditBooleanQuestionRequest
    | EditTextQuestionRequest;

export interface CreateSurveyResponse {
    questionId: number;
}

export interface SurveyPeriod {
    id: number;
    surveyGroupId: number;
    name: string;
    openedAt: Date;
    closedAt: Date;
    questions: SurveyQuestion[];
}

export interface SurveysResponse {
    surveyGroups: SurveyGroup[];
    surveys: Survey[];
}

export type EditSurveyRequest = {
    name: string;
    description: string;
};

export type EditSurveyGroupRequest = {
    name: string;
    description: string;
    confirmMessage: string;
};

export interface SurveyPeriodsResponse {
    survey: Survey;
    periods: SurveyPeriod[];
}

export type EditSurveyPeriodRequest = Pick<SurveyPeriod, 'name' | 'openedAt' | 'closedAt'>;

export interface LinkSurveyQuestionsRequest {
    questionIds: number[];
}

export interface SurveyBaseResponse {
    id: number;
    talentId: TalentId;
    surveyPeriodId: number;
    questionId: number;
    respondedAt: Date;
}

export interface SurveyTextResponse extends SurveyBaseResponse {
    message: string;
}

export interface SurveyLikertResponse extends SurveyBaseResponse {
    score: number;
}

export const SURVEY_UNSURE_SCORE = -1;

export interface SurveyBooleanResponse extends SurveyBaseResponse {
    checked: boolean;
}

export interface SurveySelectResponse extends SurveyBaseResponse {
    options: string[];
}

export type SurveyResponse =
    | SurveyTextResponse
    | SurveyLikertResponse
    | SurveyBooleanResponse
    | SurveySelectResponse;

export interface SurveyPeriodReportResponse {
    questions: SurveyQuestion[];
    responses: SurveyTalentResponses[];
}

export type SurveyCompactResponse = {
    questionId: number;
} & ({ message: string } | { score: number } | { checked: boolean } | { options: string[] });

export interface SurveyTalentResponses {
    talentId: TalentId;
    respondedAt: Date;
    responses: SurveyCompactResponse[];
}

export interface SurveyReportResponse {
    questions: SurveyQuestion[];
    responses: SurveyResponse[];
}

export type ImportSurveyResponseRequest = {
    email: string;
    respondedAt: Date;
    responses: SurveyApplyingItem[];
};

export type SurveyApplyingItem = {
    questionId: number;
    message?: string;
    score?: number;
    checked?: boolean;
    options?: string[];
};

export type SurveyApplyingRequest = {
    responses: SurveyApplyingItem[];
};

export interface IsAppliedSurveyResponse {
    applied: boolean;
}

/**
 * Record<surveyId, teamId[]>
 */
export type MySurveyReadPermissions = Record<number, TeamId[]>;

/**
 * Record<teamId, talentId[]>
 */
export type SurveyReadPermissions = Record<TeamId, TalentId[]>;

export type SurveyReadPermissionsResponse = {
    permissions: SurveyReadPermissions;
};

export type GrantSurveyReadRequest = {
    surveyId: number;
    talentId: TalentId;
    teamId: TeamId;
};

export type ApplicableSurveysResponse = {
    surveys: ApplicableSurvey[];
};

export type GrantSurveyGroupOperateRequest = {
    surveyGroupId: number;
    talentId: TalentId;
};

export type SurveyGroupOperatePermissionsResponse = {
    talentIds: TalentId[];
};

export type SurveyMemo = {
    id: number;
    surveyPeriodId: number;
    respondentTalentId: TalentId;
    authorTalentId: TalentId;
    content: string;
    publishedAt: Date;
};

export type SurveyMemosResponse = {
    memos: SurveyMemo[];
};

export type PostSurveyMemoRequest = {
    talentId: TalentId;
    content: string;
};
