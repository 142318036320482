import { Alert, DialogContent, DialogTitle, ToggleButton, ToggleButtonGroup } from '@mui/material';
import { useState } from 'react';
import { useAddSurveyQuestion } from '../../../../../queries/surveyAdmin';
import { CheckBoxIcon, LinearScaleIcon, SubjectIcon } from '../../../../Icons';
import { useSurveyContext } from '../Context';
import { BooleanForm, LikertForm, TextForm } from '../QuestionForm';

type TabMode = 'Likert' | 'Boolean' | 'Text';

export const AddQuestionDialog = () => {
    const { survey } = useSurveyContext();
    const [activeTab, setActiveTab] = useState<TabMode>('Likert');
    const mutation = useAddSurveyQuestion(survey.id);
    return (
        <>
            <DialogTitle>「{survey.name}」に質問を追加する</DialogTitle>
            <DialogContent>
                <Alert severity="warning" sx={{ mb: 2 }}>
                    種別は後から変更できません
                </Alert>
                <ToggleButtonGroup
                    fullWidth
                    exclusive
                    color="primary"
                    value={activeTab}
                    onChange={(_, value) => {
                        if (value !== null) {
                            setActiveTab(value);
                        }
                    }}
                >
                    <ToggleButton value="Likert">
                        <LinearScaleIcon />
                        スコア
                    </ToggleButton>
                    <ToggleButton value="Boolean">
                        <CheckBoxIcon />
                        チェック
                    </ToggleButton>
                    <ToggleButton value="Text">
                        <SubjectIcon />
                        テキスト
                    </ToggleButton>
                </ToggleButtonGroup>
            </DialogContent>
            {activeTab === 'Likert' && <LikertForm question={null} mutation={mutation} />}
            {activeTab === 'Boolean' && <BooleanForm question={null} mutation={mutation} />}
            {activeTab === 'Text' && <TextForm question={null} mutation={mutation} />}
        </>
    );
};
