import type { Team } from '@spec/Organization';
import { SurveyGroup } from '@spec/Survey';
import type { Talent } from '@spec/Talent';
import dayjs from 'dayjs';
import { findChildTeams } from '../../../../domains/Organization';
import { findActivePeriod } from '../../../../domains/Survey';
import { gradeToString, NO_GRADE_LONG_TEXT, searchTalent } from '../../../../domains/Talent';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { filterByEnrollment } from '../../../talent/list';
import { NO_WORKPLACE_TEXT } from '../../../talent/list/ConditionForm';
import {
    isEnrollment,
    isHiringCategory,
    type Enrollment,
    type HiringCategory,
} from '../../../talent/list/filter';

const QUERY_DELIMITER = '-';

export interface FilterCondition {
    periodId: number;
    teamId: number | null;
    joinedYears: number[];
    hiringCategory: HiringCategory;
    employmentStatus: string[];
    employeeCode: string;
    workplaces: string[];
    grades: string[];
    secondmentType: string[];
    keyword: string;
    enrollment: Enrollment;
    notApplied: boolean;
}

const filterKeys: Record<keyof FilterCondition | 'page', string> = {
    periodId: 'periodId',
    teamId: 'teamId',
    page: 'p',
    joinedYears: 'y',
    hiringCategory: 'hc',
    employmentStatus: 'e',
    employeeCode: 'ec',
    workplaces: 'w',
    grades: 'g',
    secondmentType: 'secondment',
    keyword: 'word',
    enrollment: 'en',
    notApplied: 'notApplied',
};

const DEFAULT_HIRING_CATEGORY: HiringCategory = 'all';
const DEFAULT_ENROLLMENT: Enrollment = 'all';

export const initializeFilterCondition = (
    query: string,
    surveyGroup: SurveyGroup,
    currentTime: Date
): FilterCondition => {
    const searchParams = new URLSearchParams(query);
    return {
        periodId: (() => {
            const periodId = Number(searchParams.get(filterKeys.periodId));
            if (periodId > 0) {
                return periodId;
            }
            const periods = sortByKey(surveyGroup.periods, 'openedAt', 'desc');
            return findActivePeriod(surveyGroup, dayjs(currentTime))?.id ?? periods[0].id;
        })(),
        joinedYears: (() => {
            const joinedYears = searchParams.get(filterKeys.joinedYears);
            if (joinedYears === null) {
                return [];
            }
            return joinedYears
                .split(QUERY_DELIMITER)
                .map(Number)
                .filter((v) => Number.isInteger(v));
        })(),
        hiringCategory: (() => {
            const hiringCategory = searchParams.get(filterKeys.hiringCategory);
            return isHiringCategory(hiringCategory) ? hiringCategory : DEFAULT_HIRING_CATEGORY;
        })(),
        teamId: (() => {
            const baseTeamId = Number(searchParams.get(filterKeys.teamId));
            return baseTeamId > 0 ? baseTeamId : null;
        })(),
        employmentStatus: (() => {
            const employmentStatus = searchParams.get(filterKeys.employmentStatus);
            return employmentStatus ? employmentStatus.split(QUERY_DELIMITER) : [];
        })(),
        employeeCode: (() => {
            const employeeCode = searchParams.get(filterKeys.employeeCode);
            if (employeeCode) {
                return employeeCode.slice(0, 2);
            }
            return '';
        })(),
        workplaces: (() => {
            const workplaces = searchParams.get(filterKeys.workplaces);
            return workplaces ? workplaces.split(QUERY_DELIMITER) : [];
        })(),
        grades: (() => {
            const grades = searchParams.get(filterKeys.grades);
            return grades ? grades.split(QUERY_DELIMITER) : [];
        })(),
        secondmentType: (() => {
            const secondmentType = searchParams.get(filterKeys.secondmentType);
            return secondmentType ? secondmentType.split(QUERY_DELIMITER) : [];
        })(),
        keyword: (() => {
            return searchParams.get(filterKeys.keyword) ?? '';
        })(),
        enrollment: (() => {
            const enrollment = searchParams.get(filterKeys.enrollment);
            return isEnrollment(enrollment) ? enrollment : DEFAULT_ENROLLMENT;
        })(),
        notApplied: searchParams.get(filterKeys.notApplied) === '1',
    };
};

export const parsePage = (query: string): number => {
    const searchParams = new URLSearchParams(query);
    return Number(searchParams.get(filterKeys.page) ?? 1);
};

export const serializeFilterCondition = (
    condition: FilterCondition,
    page: number
): URLSearchParams => {
    const searchParams = new URLSearchParams();
    if (condition.periodId !== null) {
        searchParams.set(filterKeys.periodId, `${condition.periodId}`);
    }
    if (condition.joinedYears.length !== 0) {
        searchParams.set(filterKeys.joinedYears, condition.joinedYears.join(QUERY_DELIMITER));
    }
    if (condition.hiringCategory !== DEFAULT_HIRING_CATEGORY) {
        searchParams.set(filterKeys.hiringCategory, condition.hiringCategory);
    }
    if (condition.teamId !== null) {
        searchParams.set(filterKeys.teamId, `${condition.teamId}`);
    }
    if (condition.employmentStatus.length !== 0) {
        searchParams.set(
            filterKeys.employmentStatus,
            condition.employmentStatus.join(QUERY_DELIMITER)
        );
    }
    if (condition.employeeCode !== '') {
        searchParams.set(filterKeys.employeeCode, condition.employeeCode);
    }
    if (condition.workplaces.length !== 0) {
        searchParams.set(filterKeys.workplaces, condition.workplaces.join(QUERY_DELIMITER));
    }
    if (condition.grades.length !== 0) {
        searchParams.set(filterKeys.grades, condition.grades.join(QUERY_DELIMITER));
    }
    if (condition.secondmentType.length !== 0) {
        searchParams.set(filterKeys.secondmentType, condition.secondmentType.join(QUERY_DELIMITER));
    }
    if (condition.notApplied === true) {
        searchParams.set(filterKeys.notApplied, '1');
    }
    if (condition.keyword !== '') {
        searchParams.set(filterKeys.keyword, condition.keyword);
    }
    if (condition.enrollment !== DEFAULT_ENROLLMENT) {
        searchParams.set(filterKeys.enrollment, condition.enrollment);
    }
    if (page > 1) {
        searchParams.set(filterKeys.page, `${page}`);
    }
    return searchParams;
};

export const filterTalents = (
    condition: FilterCondition,
    talents: Talent[],
    teams: Team[]
): Talent[] => {
    const years = new Set(condition.joinedYears);
    const employmentStatuses = new Set(condition.employmentStatus);
    const workplaces = new Set(condition.workplaces);
    const grades = new Set(condition.grades);
    const secondmentTypes = new Set(condition.secondmentType);
    const teamIds =
        condition.teamId === null ? [] : findChildTeams(condition.teamId, teams).map((v) => v.id);
    return talents
        .filter((v) => (years.size === 0 ? true : years.has(v.joinedAt.getFullYear())))
        .filter((v) => {
            switch (condition.hiringCategory) {
                case 'newGraduate':
                    return v.isNewGraduate === true;
                case 'experienced':
                    return v.isNewGraduate === false;
            }
            return true;
        })
        .filter((v) => (condition.keyword === '' ? true : searchTalent(condition.keyword, v)))
        .filter((v) => (condition.teamId === null ? true : teamIds.includes(v.teamId)))
        .filter((v) => filterByEnrollment(v, condition.enrollment))
        .filter((v) =>
            employmentStatuses.size === 0
                ? true
                : employmentStatuses.has(v.employment.employmentStatus)
        )
        .filter((v) => {
            if (condition.employeeCode === '') {
                return true;
            }
            return v.employment.employeeCode
                .toLocaleLowerCase()
                .startsWith(condition.employeeCode.toLowerCase());
        })
        .filter((v) =>
            workplaces.size === 0 ? true : workplaces.has(v.workplace ?? NO_WORKPLACE_TEXT)
        )
        .filter((v) =>
            grades.size === 0 ? true : grades.has(gradeToString(v.grade, NO_GRADE_LONG_TEXT))
        )
        .filter((v) =>
            secondmentTypes.size === 0
                ? true
                : secondmentTypes.has(v.secondment?.assignmentType ?? '')
        );
};
