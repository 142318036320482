import {
    Box,
    Button,
    Checkbox,
    FormControlLabel,
    Radio,
    RadioGroup,
    Typography,
} from '@mui/material';
import { SURVEY_UNSURE_SCORE, type SurveyLikertQuestion } from '@spec/Survey';
import { useState } from 'react';
import { useMobile } from '../../../Theme';
import { FlexBox } from '../../FlexBox';
import type { ResponseSetter } from './ApplyForm';

export const LikertQuestionForm: React.FC<{
    question: SurveyLikertQuestion;
    setResponse: ResponseSetter;
}> = (props) => {
    const q = props.question;
    const scores = [];
    for (let i = q.minScore; i <= q.maxScore; i++) {
        scores.push(i);
    }
    const [score, setScore] = useState<number | null>(null);

    const handleScoreClick = (value: number) => {
        if (score !== value) {
            setScore(value);
            props.setResponse(q.id, value);
        } else {
            setScore(null);
            props.setResponse(q.id, null);
        }
    };

    return useMobile() ? (
        <Box mx={2} my={1}>
            <FlexBox>
                <Typography flexGrow={1} variant="body2">
                    {q.minLabel}
                </Typography>
                <Typography variant="body2" sx={{ textAlign: 'right' }}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
            <RadioGroup
                row
                sx={{
                    marginTop: 1,
                    justifyContent: 'space-between',
                    flexWrap: 'nowrap',
                }}
                value={score}
            >
                {scores.map((score) => (
                    <FormControlLabel
                        key={score}
                        label={<Typography variant="caption">{score}</Typography>}
                        value={score}
                        control={
                            <Radio
                                size="small"
                                disableFocusRipple
                                disableRipple
                                sx={{ padding: 0 }}
                                onClick={() => handleScoreClick(score)}
                            />
                        }
                        labelPlacement="top"
                        sx={{ m: 0 }}
                    />
                ))}
            </RadioGroup>
            <Box textAlign="center" mt={1}>
                <FormControlLabel
                    control={
                        <Checkbox
                            checked={score === SURVEY_UNSURE_SCORE}
                            onChange={(e) => {
                                const value = e.target.checked ? SURVEY_UNSURE_SCORE : null;
                                setScore(value);
                                props.setResponse(q.id, value);
                            }}
                            name="checked"
                            size="small"
                        />
                    }
                    label={<Typography variant="body2">分からない・回答できない</Typography>}
                />
            </Box>
            {score !== null && (
                <Box textAlign="center" mt={1}>
                    <Button
                        size="small"
                        onClick={() => {
                            setScore(null);
                            props.setResponse(q.id, null);
                        }}
                        sx={{ px: 2 }}
                    >
                        選択をリセットする
                    </Button>
                </Box>
            )}
        </Box>
    ) : (
        <Box mt={1} mb={0.5}>
            <FlexBox justifyContent="center" alignItems="flex-end" flexWrap="nowrap" gap={2}>
                <Typography variant="body2" flexBasis={0} flexGrow={1} textAlign="right">
                    {q.minLabel}
                </Typography>
                <RadioGroup
                    row
                    sx={{
                        width: '300px',
                        justifyContent: 'space-between',
                        flexWrap: 'nowrap',
                    }}
                    value={score}
                >
                    {scores.map((score) => (
                        <FormControlLabel
                            key={score}
                            label={<Typography variant="caption">{score}</Typography>}
                            value={score}
                            control={
                                <Radio
                                    size="small"
                                    disableFocusRipple
                                    disableRipple
                                    sx={{ padding: 0 }}
                                    onClick={() => handleScoreClick(score)}
                                />
                            }
                            labelPlacement="top"
                            sx={{ m: 0 }}
                        />
                    ))}
                </RadioGroup>
                <Typography variant="body2" flexBasis={0} flexGrow={1}>
                    {q.maxLabel}
                </Typography>
            </FlexBox>
            <Box display="flex" alignItems="flex-start" justifyContent="space-between">
                <Box flex={1}></Box>
                {score !== null && (
                    <Box flex={1} mt={2} textAlign="center">
                        <Button
                            size="small"
                            onClick={() => {
                                setScore(null);
                                props.setResponse(q.id, null);
                            }}
                            sx={{ px: 2 }}
                        >
                            選択をリセットする
                        </Button>
                    </Box>
                )}
                <Box flex={1} textAlign="right" mr={1}>
                    <FormControlLabel
                        control={
                            <Checkbox
                                checked={score === SURVEY_UNSURE_SCORE}
                                onChange={(e) => {
                                    const value = e.target.checked ? SURVEY_UNSURE_SCORE : null;
                                    setScore(value);
                                    props.setResponse(q.id, value);
                                }}
                                name="checked"
                                size="small"
                            />
                        }
                        label={<Typography variant="body2">分からない・回答できない</Typography>}
                    />
                </Box>
            </Box>
        </Box>
    );
};
