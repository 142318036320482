import { Dialog, DialogContent, IconButton, Tooltip, useTheme } from '@mui/material';
import { useCallback, type ReactNode } from 'react';
import { Outlet, useLocation, useNavigate } from 'react-router-dom';
import { FlexBox } from './FlexBox';
import { ClearIcon, ZoomOutMapIcon } from './Icons';

export function PageModal(props: { children: React.ReactNode }) {
    const theme = useTheme();
    const location = useLocation();
    const navigate = useNavigate();
    const onClose = useCallback(() => {
        navigate(location.state?.prevPath ?? -1, { state: { modal: false } });
    }, [location.state?.prevPath, navigate]);
    if (location.state?.modal !== true) {
        return props.children;
    }
    return (
        <Dialog
            open
            maxWidth="lg"
            fullWidth
            onClose={onClose}
            PaperProps={{ sx: { backgroundColor: theme.palette.background.default } }}
        >
            <FlexBox
                mt={2}
                mr={2}
                gap={1}
                justifyContent="flex-end"
                sx={{
                    zIndex: 10,
                    // z-index doesn't work with position: 'static' (default)
                    position: 'relative',
                }}
            >
                <Tooltip title="全画面で開く" arrow>
                    <IconButton onClick={() => navigate(location.pathname, { replace: true })}>
                        <ZoomOutMapIcon />
                    </IconButton>
                </Tooltip>
                <Tooltip title="閉じる" arrow>
                    <IconButton onClick={onClose}>
                        <ClearIcon />
                    </IconButton>
                </Tooltip>
            </FlexBox>
            <DialogContent
                sx={{
                    mt: -5,
                    zIndex: 1,
                    // z-index doesn't work with position: 'static' (default)
                    position: 'relative',
                }}
            >
                {props.children}
            </DialogContent>
        </Dialog>
    );
}

export function ModalBase(props: { path: string; element: ReactNode }) {
    const location = useLocation();
    if (location.state || location.pathname === props.path) {
        return props.element;
    }
    return <Outlet />;
}
