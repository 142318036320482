import { Avatar, SxProps } from '@mui/material';
import { Talent } from '@spec/Talent';
import React, { useEffect } from 'react';
import { reportError } from '../Errors';
import {
    getProfileImageUrl,
    isLeftTalent,
    isRestrictedTalent,
    isSuspendedTalent,
} from '../domains/Talent';
import { useRefState } from '../lib/AsyncResource';
import { useAvatarUpdateContext } from './Context';
import { PersonOutlineIcon } from './Icons';

export type AvatarSize = 'small' | 'medium' | 'mediumLarge' | 'large' | 'xlarge';

export const avatarSize: Record<AvatarSize, number> = {
    small: 20,
    medium: 36,
    mediumLarge: 80,
    large: 120,
    xlarge: 180,
};

const getSuffix = (size: AvatarSize): string => {
    switch (size) {
        case 'small':
        case 'medium':
            return '_s';
        case 'mediumLarge':
        case 'large':
        case 'xlarge':
            return '_m';
        default:
            throw Error(`unexpected size: ${size}`);
    }
};

const shouldUseLongText = (size: AvatarSize): boolean => {
    return size === 'large' || size === 'xlarge';
};

export const TalentAvatar: React.FC<{
    size: AvatarSize;
    talent: Talent;
}> = (props) => {
    const sx: SxProps = {
        width: avatarSize[props.size],
        height: avatarSize[props.size],
    };
    const [imageUrl, setImageUrl] = useRefState<string | null>(null);
    const { timestamp } = useAvatarUpdateContext();
    useEffect(() => {
        getProfileImageUrl(props.talent, getSuffix(props.size))
            .then((url) => {
                if (url !== null) {
                    setImageUrl(url);
                }
            })
            .catch((e) => {
                reportError(e);
                setImageUrl(null);
            });
    }, [timestamp, props.talent, setImageUrl, props.size]);
    if (isLeftTalent(props.talent)) {
        const text = shouldUseLongText(props.size) ? '退職済み' : '退';
        return (
            <Avatar sx={sx} variant="rounded">
                {text}
            </Avatar>
        );
    }
    if (isSuspendedTalent(props.talent)) {
        const text = shouldUseLongText(props.size) ? '停止済み' : '停';
        return (
            <Avatar sx={sx} variant="rounded">
                {text}
            </Avatar>
        );
    }
    if (isRestrictedTalent(props.talent)) {
        return (
            <Avatar sx={sx} variant="rounded">
                <PersonOutlineIcon sx={{ width: '75%', height: '75%' }} />
            </Avatar>
        );
    }
    return <Avatar sx={sx} variant="rounded" src={imageUrl ?? undefined} alt={`${timestamp}`} />;
};
