import { Box, Button, Chip, Stack, Typography } from '@mui/material';
import type { Privilege } from '@spec/Grants';
import type { Talent } from '@spec/Talent';
import { privilegeLabelMap } from '../../../../domains/Grants';
import { isAvailableTalent } from '../../../../domains/Talent';
import { queryToArray } from '../../../../queries';
import { useGrants } from '../../../../queries/grant';
import { useMeContext } from '../../../../queries/me';
import { FlexBox } from '../../../FlexBox';
import { SecurityIcon } from '../../../Icons';
import { ContextualDialog, type DialogComponents } from '../../../StableDialog';
import {
    ContextProvider,
    useDialogContext,
    usePrivilegeContext,
    useTargetTalentContext,
    type DialogMode,
} from './Context';
import { GrantDialog } from './dialogs/GrantDialog';
import { RevokeDialog } from './dialogs/RevokeDialog';

export const GrantedPrivileges = (props: { talent: Talent }) => {
    const { grants } = useMeContext();
    const maybeGrants = useGrants();
    const grantedPrivileges = new Set(
        queryToArray(maybeGrants)
            .filter((v) => v.talentId === props.talent.id)
            .map((v) => v.privilege)
    );
    if (grantedPrivileges.size === 0 && grants.grantPrivilege === false) {
        return null;
    }
    return (
        <ContextProvider talent={props.talent} grantedPrivileges={grantedPrivileges}>
            <Stack direction={{ xs: 'column', sm: 'row' }} mt={0.5} columnGap={0.5} rowGap={1}>
                <Typography mt={0.5} variant="body2" sx={{ whiteSpace: 'nowrap' }}>
                    付与されている権限 :
                </Typography>
                <ChipList />
                <Box>
                    <GrantButton />
                </Box>
            </Stack>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    GrantPrivilege: GrantDialog,
    RevokePrivilege: RevokeDialog,
};

const ChipList = () => {
    const { grants } = useMeContext();
    const { grantedPrivileges, setPrivilege } = usePrivilegeContext();
    const { setDialogMode } = useDialogContext();
    const f = (v: Privilege) =>
        grants.grantPrivilege
            ? () => {
                  setPrivilege(v);
                  setDialogMode('RevokePrivilege');
              }
            : undefined;
    return (
        <FlexBox mt={0.5} flexGrow={1} gap={0.5}>
            {[...privilegeLabelMap]
                .filter(([k]) => grantedPrivileges.has(k))
                .map(([k, v]) => (
                    <Chip
                        key={k}
                        color="primary"
                        variant="outlined"
                        sx={{ fontWeight: 'normal' }}
                        label={v}
                        onDelete={f(k)}
                    />
                ))}
        </FlexBox>
    );
};

const GrantButton = () => {
    const { setDialogMode } = useDialogContext();
    const { grants } = useMeContext();
    const { talent } = useTargetTalentContext();
    if (grants.grantPrivilege === false) {
        return null;
    }
    if (isAvailableTalent(talent) === false) {
        return null;
    }
    return (
        <Button
            variant="outlined"
            color="primary"
            startIcon={<SecurityIcon />}
            sx={{ whiteSpace: 'nowrap' }}
            onClick={() => setDialogMode('GrantPrivilege')}
        >
            権限の追加
        </Button>
    );
};
