import {
    Box,
    Divider,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Tooltip,
    useTheme,
} from '@mui/material';
import { useStorageToggle } from '../../../lib/Storage';
import { useMeContext } from '../../../queries/me';
import { RoutingPattern } from '../../../Routes';
import {
    AccountBoxIcon,
    AccountTreeIcon,
    AssignmentIndIcon,
    AutorenewIcon,
    BackHandIcon,
    CaruupeIcon,
    DashboardIcon,
    FactCheckIcon,
    FeedIcon,
    FilterNoneIcon,
    GroupsIcon,
    HistoryIcon,
    KeyboardIcon,
    PersonIcon,
    PollIcon,
    SecurityIcon,
    SportsTennisIcon,
    TerminalIcon,
} from '../../Icons';
import { NavItem } from './NavItem';
import { NavList } from './NavList';

const CARUUPE_URL = 'https://sites.google.com/cartahd.com/caruupe';

export const Carta = () => {
    const theme = useTheme();
    const { grants } = useMeContext();
    const [collapse] = useStorageToggle('navigationCollapsed');
    return (
        <>
            <NavList>
                <NavItem
                    icon={DashboardIcon}
                    label="ダッシュボード"
                    href={RoutingPattern.index}
                    paths={['', 'sabbatical-leaves']}
                />
                {(grants.showFullPersonality || grants.showHrDashboard) && (
                    <NavItem
                        icon={GroupsIcon}
                        label="HRダッシュボード"
                        href={RoutingPattern.admin}
                        paths={['admin']}
                    />
                )}
                <NavItem
                    icon={FactCheckIcon}
                    label="やることリスト"
                    href={RoutingPattern.todo}
                    paths={['todo']}
                />
                <NavItem
                    icon={PersonIcon}
                    label="人を探す"
                    href={RoutingPattern.talents}
                    paths={['talents', 'iam']}
                />
                <NavItem
                    icon={AccountTreeIcon}
                    label="組織を見る"
                    href={RoutingPattern.organization}
                    paths={['organization']}
                />
                <NavItem
                    icon={AutorenewIcon}
                    label="バリューフィードバック"
                    href={RoutingPattern.valueFeedbacks}
                    paths={['value-feedback']}
                />
                <NavItem
                    icon={KeyboardIcon}
                    label="技術力評価会"
                    href={RoutingPattern.techAssessment}
                    paths={['tech-assessment']}
                />
                <NavItem
                    icon={PollIcon}
                    label="サーベイ"
                    href={RoutingPattern.survey}
                    paths={['survey']}
                />
                <NavItem
                    icon={SportsTennisIcon}
                    label="部活・サークル"
                    href={RoutingPattern.circle}
                    paths={['circle']}
                />
                <NavItem
                    icon={AssignmentIndIcon}
                    label="求人情報"
                    href={RoutingPattern.job}
                    paths={['job']}
                />
                <NavItem
                    icon={FeedIcon}
                    label="ノート"
                    href={RoutingPattern.notes}
                    paths={['notes']}
                />
            </NavList>
            <Divider />
            <NavList>
                <NavItem
                    icon={SecurityIcon}
                    label="権限管理"
                    href={RoutingPattern.grants}
                    paths={['grants']}
                />
                {grants.screeningProfileImage === true && (
                    <NavItem
                        icon={AccountBoxIcon}
                        label="プロフィール画像審査"
                        href={RoutingPattern.profileImageScreening}
                        paths={['profile-image']}
                    />
                )}
                {grants.manageHiTouch === true && (
                    <NavItem
                        icon={BackHandIcon}
                        label="タッチ履歴"
                        href={RoutingPattern.touch}
                        paths={['touch']}
                    />
                )}
                {grants.readLogs === true && (
                    <NavItem
                        icon={HistoryIcon}
                        label="操作履歴"
                        href={RoutingPattern.logs}
                        paths={['logs']}
                    />
                )}
                <NavItem
                    icon={TerminalIcon}
                    label="APIリファレンス"
                    href={RoutingPattern.apiReference}
                    paths={['api-reference']}
                />
            </NavList>
            <Divider />
            <Box mb={-2}>
                <NavList>
                    <Tooltip
                        title="CARUUPE（別サイトを開く）"
                        followCursor
                        placement="right-end"
                        disableHoverListener={!collapse}
                    >
                        <ListItemButton
                            href={CARUUPE_URL}
                            target="_blank"
                            rel="noreferrer noopener"
                        >
                            <ListItemIcon>
                                <CaruupeIcon sx={{ color: theme.palette.nav.icon }} />
                            </ListItemIcon>
                            <ListItemText
                                primary={
                                    <>
                                        CARUUPE
                                        <FilterNoneIcon
                                            sx={{ marginLeft: '8px', fontSize: '12px' }}
                                        />
                                    </>
                                }
                            />
                        </ListItemButton>
                    </Tooltip>
                </NavList>
            </Box>
        </>
    );
};
