import { Box, TextField } from '@mui/material';
import type { SurveyTextQuestion } from '@spec/Survey';
import { useState } from 'react';
import { type ResponseSetter } from './ApplyForm';

export const TextQuestionForm: React.FC<{
    question: SurveyTextQuestion;
    setResponse: ResponseSetter;
}> = (props) => {
    const [text, setText] = useState('');
    return (
        <Box>
            <TextField
                fullWidth
                multiline
                minRows={4}
                value={text}
                onChange={(e) => {
                    setText(e.target.value);
                    props.setResponse(props.question.id, e.target.value);
                }}
            />
        </Box>
    );
};
