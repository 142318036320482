import { Box, Typography } from '@mui/material';
import { Talent } from '@spec/Talent';
import React from 'react';
import { getTalentSetDefaultImageUrl } from '../../../Routes';
import { isAvailableTalent } from '../../../domains/Talent';
import { useMeContext } from '../../../queries/me';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { PersonAddIcon } from '../../Icons';

export const SetDefaultImageButton: React.FC<{ talent: Talent }> = (props) => {
    const { grants } = useMeContext();
    if (grants.uploadProfileImage === false) {
        return null;
    }
    const disabled = isAvailableTalent(props.talent) === false;
    return (
        <ActionContainer>
            <Typography variant="body2" color="primary">
                あなたはプロフィール画像が未設定の人の画像を設定できます
            </Typography>
            <Box>
                <ActionButton
                    disabled={disabled}
                    startIcon={<PersonAddIcon />}
                    to={getTalentSetDefaultImageUrl(props.talent.employment.employeeCode)}
                >
                    プロフィール画像を設定する
                </ActionButton>
                {disabled && (
                    <Box mt={0.5}>
                        <Typography variant="body2" color="error">
                            この人のプロフィール画像は更新できません
                        </Typography>
                    </Box>
                )}
            </Box>
        </ActionContainer>
    );
};
