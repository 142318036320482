import {
    Box,
    Button,
    Stack,
    Table,
    TableBody,
    TableCell,
    tableCellClasses,
    TableRow,
    Typography,
} from '@mui/material';
import type { Talent, TalentTag } from '@spec/Talent';
import { sortByKey } from '../../../../lib/ArrayUtils';
import { useMeContext } from '../../../../queries/me';
import { DeleteIconButton, EditIconButton } from '../../../ActionButtons';
import { FlexBox } from '../../../FlexBox';
import { SellIcon } from '../../../Icons';
import { ContextualDialog, type DialogComponents } from '../../../StableDialog';
import { ContextProvider, useDialogContext, useTargetTagContext, type DialogMode } from './Contest';
import { AddTalentTagDialog } from './dialogs/AddTalentTagDialog';
import { DeleteTalentTagDialog } from './dialogs/DeleteTalentTagDialog';
import { EditTalentTagDialog } from './dialogs/EditTalentTagDialog';

export const TalentTags = (props: { talent: Talent }) => {
    return (
        <ContextProvider talent={props.talent}>
            <FlexBox alignItems="flex-end">
                <TagList tags={props.talent.tags} />
                <AddTagButton />
            </FlexBox>
            <ContextualDialog components={dialogComponents} />
        </ContextProvider>
    );
};

const TagList = (props: { tags: TalentTag[] }) => {
    const { grants } = useMeContext();
    const { setDialogMode } = useDialogContext();
    const { setTag } = useTargetTagContext();
    if (props.tags.length === 0) {
        return <Box flexGrow={1} />;
    }
    const categories = new Map<string, TalentTag[]>();
    for (const tag of sortByKey(props.tags, 'category')) {
        const key = tag.category;
        const x = categories.get(key) ?? [];
        x.push(tag);
        categories.set(key, x);
    }
    return (
        <Stack direction={{ xs: 'column', sm: 'row' }} flexGrow={1} mt={1} columnGap={4} rowGap={2}>
            {[...categories.entries()].map(([category, tags]) => (
                <Box
                    key={category}
                    sx={(theme) => ({
                        pl: 1,
                        borderLeft: `4px solid ${theme.palette.primary.light}`,
                    })}
                >
                    <Typography color="primary" variant="body2" sx={{ fontWeight: 700 }}>
                        {category}
                    </Typography>
                    <Table
                        size="small"
                        sx={{
                            width: 'auto',
                            mt: 0.5,
                            [`& .${tableCellClasses.root}`]: {
                                px: 0.5,
                                py: '2px',
                                border: 'None',
                            },
                        }}
                    >
                        <TableBody>
                            {sortByKey(tags, 'term').map((v) => (
                                <TableRow key={v.id}>
                                    <TableCell>{v.term}</TableCell>
                                    <TableCell>{v.label}</TableCell>
                                    {grants.editTalentTags === true && (
                                        <TableCell padding="checkbox">
                                            <EditIconButton
                                                onClick={() => {
                                                    setTag(v);
                                                    setDialogMode('EditTalentTag');
                                                }}
                                                sx={{ mr: 0.25 }}
                                            />
                                            <DeleteIconButton
                                                onClick={() => {
                                                    setTag(v);
                                                    setDialogMode('DeleteTalentTag');
                                                }}
                                            />
                                        </TableCell>
                                    )}
                                </TableRow>
                            ))}
                        </TableBody>
                    </Table>
                </Box>
            ))}
        </Stack>
    );
};

const AddTagButton = () => {
    const { grants } = useMeContext();
    const { setDialogMode } = useDialogContext();
    if (grants.editTalentTags === false) {
        return null;
    }
    return (
        <Button
            variant="outlined"
            startIcon={<SellIcon />}
            onClick={() => setDialogMode('AddTalentTag')}
            sx={{ mt: 1 }}
        >
            新しいタグを付ける
        </Button>
    );
};

const dialogComponents: DialogComponents<DialogMode> = {
    AddTalentTag: AddTalentTagDialog,
    EditTalentTag: EditTalentTagDialog,
    DeleteTalentTag: DeleteTalentTagDialog,
};
