import {
    Alert,
    AlertTitle,
    Box,
    Button,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Stack,
    Typography,
} from '@mui/material';
import dayjs from 'dayjs';
import { useCallback, useState } from 'react';
import { detectSexText } from '../../domains/Talent';
import { reportInfo } from '../../Errors';
import { findById } from '../../lib/ArrayUtils';
import { generateTsv } from '../../lib/Csv';
import { useMeContext } from '../../queries/me';
import { usePrivacies } from '../../queries/talent';
import { ActionButton } from '../ActionButtons';
import { useTalentsContext } from '../Context';
import { CalendarMonthIcon, ContentCopyIcon } from '../Icons';

export const CopyPrivacies = () => {
    const [dialogOpen, setDialogOpen] = useState(false);
    const [copied, setCopied] = useState(false);
    const { talents } = useTalentsContext();
    const { me } = useMeContext();
    const maybePrivacies = usePrivacies();
    const handleCopy = useCallback(() => {
        const rows = (maybePrivacies.data ?? []).map((v) => {
            const t = findById(v.talentId, talents);
            return [
                t.employment.employeeCode,
                v.birthday === null ? '' : dayjs(v.birthday).format('YYYY-MM-DD'),
                detectSexText(v.sex),
            ];
        });
        reportInfo(`${me.hitonowaId} copied privacies`);
        return navigator.clipboard
            .writeText(generateTsv(['社員番号', '生年月日', '性別'], rows))
            .then(() => setCopied(true))
            .then(() =>
                setTimeout(() => {
                    setCopied(false);
                }, 800)
            );
    }, [maybePrivacies.data, me.hitonowaId, talents]);
    return (
        <>
            <Dialog open={dialogOpen} onClose={() => setDialogOpen(false)} maxWidth="sm" fullWidth>
                <DialogTitle>生年月日・性別を取得する</DialogTitle>
                <DialogContent>
                    <Alert severity="warning">
                        <AlertTitle>個人に関するプライバシー度合いの高い情報です</AlertTitle>
                        <Typography variant="body2">
                            取り扱いには十分注意し、この情報を他者に直接提供しないでください
                        </Typography>
                    </Alert>
                    <Stack spacing={1} mt={2}>
                        <Typography>
                            退職者も含めた全員の情報をまとめてクリップボードにコピーできます。
                        </Typography>
                        <Typography>生年月日・性別ともに未登録の人は出力されません。</Typography>
                    </Stack>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setDialogOpen(false)}
                        size="medium"
                        variant="outlined"
                        tabIndex={2}
                    >
                        キャンセル
                    </Button>
                    <Box flexGrow={1} textAlign="right">
                        <Button
                            onClick={handleCopy}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<ContentCopyIcon fontSize="small" />}
                            disabled={copied || maybePrivacies.isPending}
                            tabIndex={1}
                        >
                            {copied ? 'コピーしました' : 'コピーする'}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <ActionButton startIcon={<CalendarMonthIcon />} onClick={() => setDialogOpen(true)}>
                生年月日・性別を取得する
            </ActionButton>
        </>
    );
};
