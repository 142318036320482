import { RoutingPattern } from '../../Routes';

export const getApplySurveyUrl = (groupId: number) => `${RoutingPattern.survey}/${groupId}`;

export const getPeriodsUrl = (groupId: number) =>
    `${RoutingPattern.survey}/admin/periods/${groupId}`;

export const getSurveyAdminUrl = (surveyId: number) =>
    `${RoutingPattern.survey}/admin/surveys/${surveyId}`;

export const getSurveyPermissionsUrl = (surveyId: number) =>
    `${RoutingPattern.survey}/admin/surveys/${surveyId}/permissions`;

export const getGroupPermissionsUrl = (groupId: number) =>
    `${RoutingPattern.survey}/admin/groups/${groupId}/permissions`;

export const getGroupPreviewUrl = (groupId: number) =>
    `${RoutingPattern.survey}/admin/groups/${groupId}/preview`;

export const getReportUrl = (groupId: number, employeeCode?: string, periodName?: string) => {
    const baseUrl = `${RoutingPattern.survey}/report/${groupId}`;
    if (employeeCode !== undefined) {
        const reportUrl = `${baseUrl}?code=${employeeCode}`;
        if (periodName !== undefined) {
            return `${reportUrl}#${periodName}`;
        }
        return reportUrl;
    }
    return baseUrl;
};
