import { Box, Checkbox, FormControlLabel, Typography } from '@mui/material';
import type { SurveyBooleanQuestion } from '@spec/Survey';
import { useState } from 'react';
import type { ResponseSetter } from './ApplyForm';

export const BooleanQuestionForm: React.FC<{
    question: SurveyBooleanQuestion;
    setResponse: ResponseSetter;
}> = (props) => {
    const [checked, setChecked] = useState(false);
    return (
        <Box ml={2}>
            <FormControlLabel
                control={
                    <Checkbox
                        checked={checked}
                        onChange={(e) => {
                            setChecked(e.target.checked);
                            props.setResponse(props.question.id, e.target.checked);
                        }}
                        name="checked"
                        size="small"
                    />
                }
                label={<Typography variant="body2">{props.question.label}</Typography>}
            />
        </Box>
    );
};
