import { Box, Card, CardContent, Divider, Tooltip, Typography } from '@mui/material';
import { SurveyGroup, SurveyMemo } from '@spec/Survey';
import dayjs from 'dayjs';
import React from 'react';
import { fullName } from '../../../../domains/Talent';
import { findById, sortByKey } from '../../../../lib/ArrayUtils';
import { useTalentsContext } from '../../../Context';
import { FlexBox } from '../../../FlexBox';
import { Markdown } from '../../../Markdown';
import { RouterLink } from '../../../RouterLink';
import { TalentAvatar } from '../../../TalentAvatar';
import { getReportUrl } from '../../urls';

export const Memo: React.FC<{ group: SurveyGroup; memos: SurveyMemo[] }> = (props) => {
    const { talents } = useTalentsContext();
    if (props.memos.length === 0) {
        return null;
    }
    const memo = sortByKey(props.memos, 'publishedAt', 'desc')[0];
    const author = findById(memo.authorTalentId, talents);
    const headline = memo.content.split('\n')[0];
    const talent = findById(memo.respondentTalentId, talents);
    const period = findById(memo.surveyPeriodId, props.group.periods);
    return (
        <Tooltip
            components={{ Tooltip: Box }}
            placement="top-start"
            title={
                <Card>
                    <CardContent>
                        <FlexBox gap={1}>
                            <TalentAvatar talent={author} size="small" />
                            <Typography variant="body2">{fullName(author)}</Typography>
                            <Typography variant="body2">
                                {dayjs(memo.publishedAt).format('YYYY-MM-DD HH:mm:ss')}
                            </Typography>
                        </FlexBox>
                        <Box my={1}>
                            <Divider />
                        </Box>
                        <Markdown source={memo.content} variant="body2" />
                        {props.memos.length > 1 && (
                            <Box textAlign="right">
                                <RouterLink
                                    variant="body2"
                                    to={getReportUrl(
                                        props.group.id,
                                        talent.employment.employeeCode,
                                        period.name
                                    )}
                                >
                                    他{props.memos.length - 1}件を見る
                                </RouterLink>
                            </Box>
                        )}
                    </CardContent>
                </Card>
            }
        >
            <Typography variant="body2">{headline}</Typography>
        </Tooltip>
    );
};
