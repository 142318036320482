import { Checkbox, DialogContent, MenuItem, TextField, Typography } from '@mui/material';
import type {
    EditSurveyQuestionRequest,
    SurveyBooleanQuestion,
    SurveyLikertQuestion,
    SurveyQuestion,
    SurveySelectQuestion,
    SurveyTextQuestion,
} from '@spec/Survey';
import type { UseMutationResult } from '@tanstack/react-query';
import { FormText, FormTextField, useForm, useFormText, type Form } from '../../../../lib/Form';
import { maxLength, numberRange, numeric, required } from '../../../../lib/Form/Validators';
import { FlexBox } from '../../../FlexBox';
import { DialogActionButtons, DialogRow } from '../../../StableDialog';
import { useDialogContext } from './Context';

export const LikertForm = (props: {
    question: SurveyLikertQuestion | null;
    mutation: UseMutationResult<void, Error, EditSurveyQuestionRequest>;
}) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q?.shortTitle ?? '', [required, maxLength(191)]),
        title: useFormText(q?.title ?? '', [required, maxLength(191)]),
        minLabel: useFormText(q?.minLabel ?? '', [required, maxLength(191)]),
        maxLabel: useFormText(q?.maxLabel ?? '', [required, maxLength(191)]),
        minScore: useFormText(`${q?.minScore ?? 1}`, [required, numeric, numberRange(0, 10)]),
        maxScore: useFormText(`${q?.maxScore ?? 7}`, [required, numeric, numberRange(1, 10)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="スコアとラベル">
                    <FlexBox gap={1}>
                        <ScoreRange formText={form.minScore} min={0} max={9} />
                        <FormTextField formText={form.minLabel} />
                        <Typography flexGrow={1} textAlign="center">
                            〜
                        </Typography>
                        <FormTextField formText={form.maxLabel} />
                        <ScoreRange formText={form.maxScore} min={1} max={10} />
                    </FlexBox>
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                question={q}
                mutation={props.mutation}
                serialize={() => {
                    const args = form.serialize();
                    return {
                        ...args,
                        minScore: Number(args.minScore),
                        maxScore: Number(args.maxScore),
                    };
                }}
            />
        </>
    );
};

const ScoreRange = (props: { formText: FormText; min: number; max: number }) => {
    const { formText } = props;
    const scoreRange: number[] = [];
    for (let i = props.min; i <= props.max; i++) {
        scoreRange.push(i);
    }
    return (
        <TextField
            select
            value={formText.value}
            onChange={(e) => formText.setValue(e.target.value)}
        >
            {scoreRange.map((v) => (
                <MenuItem key={v} value={`${v}`}>
                    {v}
                </MenuItem>
            ))}
        </TextField>
    );
};

export const BooleanForm = (props: {
    question: SurveyBooleanQuestion | null;
    mutation: UseMutationResult<void, Error, EditSurveyQuestionRequest>;
}) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q?.shortTitle ?? '', [required, maxLength(191)]),
        title: useFormText(q?.title ?? '', [required, maxLength(191)]),
        label: useFormText(q?.label ?? '', [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
                <DialogRow label="チェックボックスのラベル">
                    <Checkbox disabled checked />
                    <FormTextField formText={form.label} />
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                question={q}
                mutation={props.mutation}
                serialize={form.serialize}
            />
        </>
    );
};

export const TextForm = (props: {
    question: SurveyTextQuestion | null;
    mutation: UseMutationResult<void, Error, EditSurveyQuestionRequest>;
}) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q?.shortTitle ?? '', [required, maxLength(191)]),
        title: useFormText(q?.title ?? '', [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                question={q}
                mutation={props.mutation}
                serialize={form.serialize}
            />
        </>
    );
};

export const SelectForm = (props: {
    question: SurveySelectQuestion | null;
    mutation: UseMutationResult<void, Error, EditSurveyQuestionRequest>;
}) => {
    const q = props.question;
    const form = useForm({
        shortTitle: useFormText(q?.shortTitle ?? '', [required, maxLength(191)]),
        title: useFormText(q?.title ?? '', [required, maxLength(191)]),
    });
    return (
        <>
            <DialogContent>
                <DialogRow label="短いタイトル">
                    <FormTextField formText={form.shortTitle} />
                </DialogRow>
                <DialogRow label="タイトル">
                    <FormTextField fullWidth formText={form.title} />
                </DialogRow>
            </DialogContent>
            <Actions
                form={form}
                question={q}
                mutation={props.mutation}
                serialize={form.serialize}
            />
        </>
    );
};

const Actions = (props: {
    form: Form<any>;
    question: SurveyQuestion | null;
    mutation: UseMutationResult<void, Error, EditSurveyQuestionRequest>;
    serialize: () => EditSurveyQuestionRequest;
}) => {
    const { closeDialog } = useDialogContext();
    const label = props.question === null ? '追加' : '更新';
    return (
        <DialogActionButtons
            form={props.form}
            submitLabel={`この質問を${label}する`}
            cancelLabel={`${label}せずに閉じる`}
            errorMessage={`${label}に失敗しました`}
            onSubmit={() => props.mutation.mutateAsync(props.serialize())}
            closeDialog={closeDialog}
        />
    );
};
