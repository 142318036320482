import { DialogContent, DialogTitle } from '@mui/material';
import { FormTextField, useForm, useFormText } from '../../../../../lib/Form';
import { required } from '../../../../../lib/Form/Validators';
import { useAddSurvey } from '../../../../../queries/surveyAdmin';
import { DialogActionButtons, DialogRow } from '../../../../StableDialog';
import { useDialogContext, useTargetGroupContext } from '../Context';

export const AddSurveyDialog: React.FC = () => {
    const { closeDialog } = useDialogContext();
    const { group } = useTargetGroupContext();
    if (group === null) {
        throw Error('target group was not set');
    }
    const form = useForm({
        name: useFormText('', [required]),
        description: useFormText('', []),
    });
    const mutation = useAddSurvey(group.id);
    return (
        <>
            <DialogTitle>「{group.name}」にサーベイを追加する</DialogTitle>
            <DialogContent>
                <DialogRow label="名称">
                    <FormTextField autoFocus fullWidth formText={form.name} />
                </DialogRow>
                <DialogRow label="詳細">
                    <FormTextField fullWidth multiline minRows={4} formText={form.description} />
                </DialogRow>
            </DialogContent>
            <DialogActionButtons
                form={form}
                submitLabel="この内容でサーベイを追加する"
                cancelLabel="追加せずに閉じる"
                errorMessage="追加に失敗しました"
                onSubmit={() => mutation.mutateAsync(form.serialize())}
                closeDialog={closeDialog}
            />
        </>
    );
};
