import {
    AbsentRequest,
    EditEmploymentRequest,
    EditTransferRequest,
    GradesResponse,
    ReturnRequest,
    SabbaticalLeaveBaseDateRequest,
    TalentsResponse,
    TransferTalentRequest,
    UpdateTalentRequest,
    WithdrawTalentRequest,
    type EmployeeCodeResponse,
    type PrivaciesResponse,
    type Secondment,
    type TalentTag,
    type TalentTagRequest,
} from '@spec/Talent';
import type { MutualTouchHistory } from '@spec/Touch';
import {
    MutationFunction,
    QueryClient,
    useMutation,
    useQuery,
    useQueryClient,
} from '@tanstack/react-query';
import { ApplicationError, AuthError } from '../Errors';
import { gradeToString } from '../domains/Talent';
import { Gateway, blobToBase64, useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useTalents = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talents],
        queryFn: () => gateway.get<TalentsResponse>('/talents').then((res) => res.talents),
    });
};

export const useTalent = (employeeCode: string) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talents, employeeCode],
        queryFn: () => gateway.fetchTalent(employeeCode),
    });
};

export const useRetrieveHitonowaId = (hitonowaId: string) => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talents, 'iam', hitonowaId],
        queryFn: () =>
            gateway.get<EmployeeCodeResponse>(`/iam/${hitonowaId}`).then((res) => res.employeeCode),
    });
};

const RETRY_LIMIT = 3;

export const useLeaveOfAbsences = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.leaveOfAbsences],
        queryFn: () => gateway.getLeaveOfAbsences(),
        retry: (count, error) => {
            if (error instanceof AuthError && error.code === 'auth/permission-denied') {
                return false;
            }
            if (count >= RETRY_LIMIT) {
                return false;
            }
            return true;
        },
    });
};

export const useSabbaticalLeaves = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.sabbaticalLeaves],
        queryFn: () => gateway.getSabbaticalLeaves(),
    });
};

export const useNextSabbaticalLeaves = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.nextSabbaticalLeaves],
        queryFn: () => gateway.getNextSabbaticalLeaves(),
    });
};

export const useGrades = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.grades],
        queryFn: () =>
            gateway
                .get<GradesResponse>('/grades')
                .then((res) => new Map(res.grades.map((grade) => [gradeToString(grade), grade]))),
    });
};

export const setSabbaticalLeaveBasedDate = (
    gateway: Gateway,
    queryClient: QueryClient,
    employeeCode: string,
    args: SabbaticalLeaveBaseDateRequest
) => {
    return gateway.post(`/sabbatical-leaves/base/${employeeCode}`, args).then(() => {
        return Promise.all([
            queryClient.invalidateQueries({ queryKey: [queryKey.sabbaticalLeaves] }),
            queryClient.invalidateQueries({ queryKey: [queryKey.nextSabbaticalLeaves] }),
        ]);
    });
};

export const invalidateTalents = (queryClient: QueryClient) =>
    queryClient.invalidateQueries({ queryKey: [queryKey.talents] });

const useTalentMutation = <TVariables = void>(mutationFn: MutationFunction<void, TVariables>) => {
    const queryClient = useQueryClient();
    return useMutation<void, Error, TVariables>({
        mutationFn,
        onSuccess: () => invalidateTalents(queryClient),
    });
};

export const useUpdateTalent = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: UpdateTalentRequest) =>
        gateway.put(`/talents/${employeeCode}`, args)
    );
};

export const useAddSecondment = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: Secondment) =>
        gateway.post(`/talents/${employeeCode}/secondment`, args)
    );
};

export const useUpdateSecondment = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: Secondment) =>
        gateway.put(`/talents/${employeeCode}/secondment`, args)
    );
};

export const useDeleteSecondment = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation(() => gateway.delete(`/talents/${employeeCode}/secondment`));
};

export const useAddEmployment = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: EditEmploymentRequest) =>
        gateway.post(`/talents/${employeeCode}/employments`, args)
    );
};

export const useUpdateEmployment = (employmentId: number) => {
    const gateway = useGateway();
    return useTalentMutation((args: EditEmploymentRequest) =>
        gateway.put(`/employments/${employmentId}`, args)
    );
};

export const useDeleteEmployment = (employmentId: number) => {
    const gateway = useGateway();
    return useTalentMutation(() => gateway.delete(`/employments/${employmentId}`));
};

export const useAddTransfer = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: EditTransferRequest) =>
        gateway.post(`/talents/${employeeCode}/transfer-history`, args)
    );
};

export const useUpdateTransfer = (transferId: number) => {
    const gateway = useGateway();
    return useTalentMutation((args: EditTransferRequest) =>
        gateway.put(`/transfer/${transferId}`, args)
    );
};

export const useDeleteTransfer = (transferId: number) => {
    const gateway = useGateway();
    return useTalentMutation(() => gateway.delete(`/transfer/${transferId}`));
};

export const useTransferTalent = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: TransferTalentRequest) =>
        gateway.post(`/talents/${employeeCode}/transfer`, args)
    );
};

export const useWithdrawTalent = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: WithdrawTalentRequest) =>
        gateway.post(`/talents/${employeeCode}/withdraw`, args)
    );
};

export const useSuspendTalent = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((suspend: boolean) => {
        const url = `/talents/${employeeCode}/suspend`;
        return suspend ? gateway.post(url) : gateway.delete(url);
    });
};

export const useSetDefaultImage = (employeeCode: string) => {
    const gateway = useGateway();
    const url = `/talents/${employeeCode}/profile-image`;
    return useTalentMutation(async (blob: Blob | null) => {
        if (blob === null) {
            return gateway.delete(url);
        }
        const image = await blobToBase64(blob);
        if (typeof image !== 'string') {
            throw new ApplicationError('unknown image type');
        }
        return gateway.post(url, { image });
    });
};

export const useAbsentTalent = (employeeCode: string) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: AbsentRequest) => gateway.post(`/talents/${employeeCode}/absent`, args),
        onSuccess: () => {
            return Promise.all([
                invalidateTalents(queryClient),
                queryClient.invalidateQueries({ queryKey: [queryKey.leaveOfAbsences] }),
            ]);
        },
    });
};

export const useReturnTalent = (employeeCode: string) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: (args: ReturnRequest) => gateway.post(`/talents/${employeeCode}/return`, args),
        onSuccess: () => {
            return Promise.all([
                invalidateTalents(queryClient),
                queryClient.invalidateQueries({ queryKey: [queryKey.leaveOfAbsences] }),
            ]);
        },
    });
};

export const usePrivacies = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talents, 'privacies'],
        queryFn: () => gateway.get<PrivaciesResponse>('/privacies').then((res) => res.privacies),
    });
};

export const useAddTalentTag = (employeeCode: string) => {
    const gateway = useGateway();
    return useTalentMutation((args: TalentTagRequest) =>
        gateway.post(`/talents/${employeeCode}/tags`, args)
    );
};

export const useUpdateTalentTag = (tag: TalentTag) => {
    const gateway = useGateway();
    return useTalentMutation((args: TalentTagRequest) =>
        gateway.put(`/talent-tags/${tag.id}`, args)
    );
};

export const useDeleteTalentTag = (tag: TalentTag) => {
    const gateway = useGateway();
    return useTalentMutation(() => gateway.delete(`/talent-tags/${tag.id}`));
};

export const useAllTouches = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.talents, 'touches'],
        queryFn: () =>
            gateway.get<{ touches: MutualTouchHistory[] }>('/touches').then((res) => res.touches),
    });
};
