import { Validator } from './Contract';

export const required: Validator<string | Date | number | boolean | null> = (v) => {
    if (typeof v === 'string') {
        return /^\s*$/.test(v) ? 'required' : null;
    }
    if (typeof v === 'boolean') {
        return v ? null : 'required';
    }
    if (v instanceof Date || Number.isSafeInteger(v)) {
        return null;
    }
    return 'required';
};

export const numeric: Validator = (v) => {
    if (v === '' || /^[0-9]+$/.test(v)) {
        return null;
    }
    return 'numeric';
};

export const numberRange = (min: number, max: number): Validator => {
    const f: Validator = (v) => {
        if (v === '') {
            return null;
        }
        const vv = Number(v);
        return min <= vv && vv <= max ? null : 'numberRange';
    };
    return f;
};

export const telephoneNumber: Validator = (v) => {
    if (v === '' || /^[0-9][0-9-]{8,11}[0-9]$/.test(v)) {
        return null;
    }
    return 'tel';
};

export const unique = (items: string[]): Validator => {
    return (v) => (items.includes(v) ? 'unique' : null);
};

export const maxLength = (maxLength: number): Validator => {
    const f: Validator = (v) => {
        return v.length > maxLength ? 'maxLength' : null;
    };
    return f;
};

export const countBytes = (text: string) => {
    const blob = new Blob([text], { type: 'text/plain; charset=utf-8' });
    return blob.size;
};

export const maxBytes = (maxBytes: number): Validator => {
    const f: Validator = (v) => {
        return countBytes(v) > maxBytes ? 'maxBytes' : null;
    };
    return f;
};

export const url: Validator = (v) => {
    if (typeof v !== 'string') {
        return 'url';
    }
    if (v === '') {
        return null;
    }
    try {
        const url = new URL(v);
        return ['http:', 'https:'].includes(url.protocol) ? null : 'url';
    } catch {
        return 'url';
    }
};

export const githubId: Validator = (v) => {
    if (v === '') {
        return null;
    }
    if (/^[0-9a-zA-Z][-0-9a-zA-Z]{0,38}$/.test(v) === false) {
        return 'githubId';
    }
    const ngWords = ['voyagegroup', 'pulls', 'issues', 'codespaces', 'marketplace', 'explore'];
    if (ngWords.includes(v)) {
        return 'githubId';
    }
    return null;
};
