import type { ProfileImage, UnscreenedImagesResponse } from '@spec/profileImage';
import { useMutation, useQuery, useQueryClient } from '@tanstack/react-query';
import { useGateway } from '../stores/Gateway';
import { queryKey } from './queryKey';

export const useUnscreenedProfileImages = () => {
    const gateway = useGateway();
    return useQuery({
        queryKey: [queryKey.unscreenedProfileImages],
        queryFn: async () =>
            gateway
                .get<UnscreenedImagesResponse>('/profile-image-screenings/unscreened')
                .then((res) => res.images),
    });
};

export const useScreeningProfileImages = (images: ProfileImage[]) => {
    const gateway = useGateway();
    const queryClient = useQueryClient();
    return useMutation({
        mutationFn: async (approveImageId: number | null) => {
            // 採用する画像があれば承認
            if (approveImageId !== null) {
                await gateway.post(`/profile-image-screenings/${approveImageId}/approve`);
            }

            // 残りの画像はすべて却下
            const rejectImages = images.filter((img) => img.id !== approveImageId);
            for (const image of rejectImages) {
                await gateway.post(`/profile-image-screenings/${image.id}/reject`);
            }
        },
        onSettled: () =>
            queryClient.invalidateQueries({ queryKey: [queryKey.unscreenedProfileImages] }),
    });
};
