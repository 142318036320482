import {
    Avatar,
    Box,
    Button,
    Card,
    CardContent,
    Chip,
    Stack,
    Typography,
    type ChipProps,
} from '@mui/material';
import type { ProfileImage } from '@spec/profileImage';
import type { TalentId } from '@spec/Talent';
import dayjs from 'dayjs';
import { getDownloadURL, getStorage, ref } from 'firebase/storage';
import { useEffect, useState } from 'react';
import { firebaseApp } from '../../App';
import { getBaseTeamName, getCorporate } from '../../domains/Organization';
import { fullName } from '../../domains/Talent';
import { reportError } from '../../Errors';
import { findById } from '../../lib/ArrayUtils';
import { useRefState } from '../../lib/AsyncResource';
import { useScreeningProfileImages, useUnscreenedProfileImages } from '../../queries/profileImage';
import { getTalentUrl } from '../../Routes';
import { useTalentsContext, useTeamsContext } from '../Context';
import { FlexBox } from '../FlexBox';
import { AccountBoxIcon, CheckIcon, ClearIcon } from '../Icons';
import { NoItems } from '../NoItems';
import { Pager } from '../Pager';
import { PageTitle } from '../PageTitle';
import { RequirePrivilege } from '../RequirePrivilege';
import { RouterLink } from '../RouterLink';
import { avatarSize } from '../TalentAvatar';
import { WaitQuery } from '../WaitLoading';

export const ProfileImageScreening = () => {
    const maybeImages = useUnscreenedProfileImages();
    return (
        <RequirePrivilege fn={(grants) => grants.screeningProfileImage}>
            <PageTitle icon={AccountBoxIcon} title="プロフィール画像の審査" />
            <WaitQuery query={maybeImages}>
                {({ data }) => <GroupedProfileImages images={data} />}
            </WaitQuery>
        </RequirePrivilege>
    );
};

const GroupedProfileImages = (props: { images: ProfileImage[] }) => {
    const groupedImages = Object.groupBy(props.images, (v) => v.talentId);
    const talentCount = Object.keys(groupedImages).length;
    const [page, setPage] = useState(1);
    const itemsPerPage = 10;
    const entries = Object.entries(groupedImages);
    const currentEntries = entries.slice((page - 1) * itemsPerPage, page * itemsPerPage);
    if (currentEntries.length === 0) {
        return <NoItems mt={8}>審査待ちのプロフィール画像はありません</NoItems>;
    }
    return (
        <Stack spacing={2}>
            <Pager amount={talentCount} current={page} perItems={itemsPerPage} setPage={setPage} />
            {currentEntries.map(([talentId, images]) => (
                <TalentImageCard key={talentId} talentId={Number(talentId)} images={images ?? []} />
            ))}
            <Pager amount={talentCount} current={page} perItems={itemsPerPage} setPage={setPage} />
        </Stack>
    );
};

const TalentImageCard = (props: { talentId: TalentId; images: ProfileImage[] }) => {
    const { talents } = useTalentsContext();
    const { teams } = useTeamsContext();
    const talent = findById(props.talentId, talents);
    const corporate = getCorporate(talent, teams);
    const baseTeamName = getBaseTeamName(talent, teams);
    const mutation = useScreeningProfileImages(props.images);
    return (
        <Card>
            <CardContent>
                <FlexBox gap={2}>
                    <FlexBox gap={1}>
                        <Typography>{talent.employment.employeeCode}</Typography>
                        <RouterLink to={getTalentUrl(talent.employment.employeeCode)}>
                            <Typography>{fullName(talent)}</Typography>
                        </RouterLink>
                    </FlexBox>
                    <FlexBox gap={1}>
                        <Typography variant="body2">{corporate.name}</Typography>
                        <Typography variant="body2">{baseTeamName}</Typography>
                        <Typography variant="body2">
                            {talent.employment.employmentStatus}
                        </Typography>
                    </FlexBox>
                </FlexBox>
                <FlexBox mt={1} gap={2}>
                    {(props.images ?? []).map((image) => (
                        <Stack key={image.id} textAlign="center" spacing={0.5}>
                            <PreviewAvatar image={image} />
                            <Typography variant="body2">
                                {dayjs(image.uploadedAt).format('YYYY-MM-DD HH:mm')}
                            </Typography>
                            <Button
                                variant="contained"
                                color="primary"
                                startIcon={<CheckIcon />}
                                disabled={mutation.isPending}
                                onClick={() => mutation.mutate(image.id)}
                            >
                                採用する
                            </Button>
                        </Stack>
                    ))}
                </FlexBox>
                <Box mt={2}>
                    <Button
                        variant="outlined"
                        color="primary"
                        startIcon={<ClearIcon />}
                        disabled={mutation.isPending}
                        onClick={() => mutation.mutate(null)}
                    >
                        すべて不採用にする
                    </Button>
                </Box>
            </CardContent>
        </Card>
    );
};

const PreviewAvatar = (props: { image: ProfileImage }) => {
    const [imageUrl, setImageUrl] = useRefState<string | null>(null);
    useEffect(() => {
        getDownloadURL(ref(getStorage(firebaseApp), `${props.image.imagePath}_m`))
            .then(setImageUrl)
            .catch((e) => {
                reportError(e);
                setImageUrl(null);
            });
    }, [props.image.imagePath, setImageUrl]);
    const chipProps: ChipProps =
        props.image.talentId === props.image.uploadTalentId
            ? { label: 'SELF', color: 'secondary' }
            : { label: 'HR', color: 'primary' };
    return (
        <Box sx={{ position: 'relative' }}>
            <Avatar
                sx={{
                    width: avatarSize.large,
                    height: avatarSize.large,
                }}
                variant="rounded"
                src={imageUrl ?? undefined}
            />
            <Chip {...chipProps} sx={{ position: 'absolute', right: 2, top: 2 }} />
        </Box>
    );
};
