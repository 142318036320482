import { Box } from '@mui/material';
import React from 'react';
import { Form, FormTextField, useForm, useFormText } from '../../../../lib/Form';
import { required } from '../../../../lib/Form/Validators';
import { DialogRow } from '../../../StableDialog';

interface Elements {
    name: string;
    description: string;
    confirmMessage: string;
}
type GroupForm = Form<Elements>;

export const useGroupForm = (v: Elements) =>
    useForm<typeof v>({
        name: useFormText(v.name, [required]),
        description: useFormText(v.description, [required]),
        confirmMessage: useFormText(v.confirmMessage, []),
    });

export const GroupFormContent: React.FC<{ form: GroupForm }> = ({ form }) => {
    return (
        <Box>
            <DialogRow label="名称">
                <FormTextField autoFocus fullWidth formText={form.name} />
            </DialogRow>
            <DialogRow label="概要">
                <FormTextField fullWidth multiline rows={10} formText={form.description} />
            </DialogRow>
            <DialogRow label="回答直前の確認メッセージ">
                <FormTextField autoFocus fullWidth formText={form.confirmMessage} />
            </DialogRow>
        </Box>
    );
};
