import { Box, Button, FormControlLabel, Radio, RadioGroup, Typography } from '@mui/material';
import type { SurveySelectQuestion } from '@spec/Survey';
import { useState } from 'react';
import { type ResponseSetter } from './ApplyForm';

export const SelectQuestionForm: React.FC<{
    question: SurveySelectQuestion;
    setResponse: ResponseSetter;
}> = (props) => {
    const [selectedItem, setSelectedItem] = useState('');
    const q = props.question;
    return (
        <Box>
            <RadioGroup sx={{ mx: 2, my: 1 }} value={selectedItem}>
                {q.options.map((option) => (
                    <FormControlLabel
                        key={option}
                        label={<Typography>{option}</Typography>}
                        value={option}
                        control={
                            <Radio
                                onClick={() => {
                                    if (selectedItem !== option) {
                                        props.setResponse(q.id, [option]);
                                        setSelectedItem(option);
                                    } else {
                                        props.setResponse(q.id, null);
                                        setSelectedItem('');
                                    }
                                }}
                            />
                        }
                    />
                ))}
            </RadioGroup>
            {selectedItem !== '' && (
                <Box textAlign="right" mr={1}>
                    <Button
                        size="small"
                        onClick={() => {
                            props.setResponse(q.id, null);
                            setSelectedItem('');
                        }}
                        sx={{ px: 2 }}
                    >
                        選択をリセットする
                    </Button>
                </Box>
            )}
        </Box>
    );
};
