import { Box, Divider, Typography } from '@mui/material';
import { Privilege } from '@spec/Grants';
import React from 'react';
import { privilegeLabelMap } from '../../domains/Grants';
import { HelpAccordion } from '../HelpAccordion';

const descriptions: { [K in Privilege]: string[] } = {
    EDIT_TALENT: [
        '対象チームに所属する従業員の情報を更新できる。権限の及ぶ部署に所属する従業員の新規登録も可能になる。',
        '対象チームそのものの編集も可能になる（遠からず別の権限に切り出す想定）。',
    ],

    READ_LOGS: ['操作ログを閲覧できる。'],
    SHOW_LEAVED_TALENT: ['退職者や停止アカウントのメールアドレスや氏名などを閲覧できる。'],
    GRANT_PRIVILEGE: ['アカウントに対して権限の付与したり剥奪したりできる。'],
    SHOW_FULL_PERSONALITY: [
        '従業員のプライバシー情報（生年月日、性別、ボーナス休暇発生日）を閲覧できる。システムの機能は存在するが、現在は実際のデータが入っていない。',
    ],
    EDIT_WORKPLACES: ['勤務地の選択肢を編集できる。'],
    MANAGE_CIRCLE: ['サークルとそのメンバーを編集できる。'],
    MANAGE_VALUE_FEEDBACK: ['バリューフィードバックの作成や編集、記入内容の確認ができる。'],
    UPLOAD_PROFILE_IMAGE: ['各従業員のプロフィール画像をアップロードできる。'],
    SCREENING_PROFILE_IMAGE: ['アップロードされたプロフィール画像を審査できる。'],
    MANAGE_TODO: ['やることを登録したり、対象者の達成状況を確認したりできる。'],
    DOWNLOAD_CSV: [
        'UIの各場面でCSVダウンロードが開放される。APIから取得できる情報そのものは変わらない。',
    ],
    MANAGE_SURVEY: ['サーベイを作ったり、回答を取り込んだり、閲覧権限を設定できる。'],
    MANAGE_DIVE: ['DIVEの募集要項を掲載できる。'],
    SHOW_HR_DASHBOARD: ['HRダッシュボードを閲覧できる。'],
    EDIT_TALENT_TAGS: ['人にタグを付けられる。'],
    MANAGE_HITOUCH: ['タッチの全記録を確認できる。'],
};

export const Reference: React.FC = () => {
    return (
        <HelpAccordion title="権限の種類とそれぞれの説明">
            <PrivilegeList />
        </HelpAccordion>
    );
};

const PrivilegeList: React.FC = () => {
    return (
        <Box>
            {[...privilegeLabelMap.entries()].map(([k, v]) => (
                <PrivilegeInfo key={k} label={v} description={descriptions[k]} />
            ))}
        </Box>
    );
};

const PrivilegeInfo: React.FC<{ label: string; description: string[] }> = (props) => {
    return (
        <Box mb={2}>
            <Box px={0.5}>
                <Typography>{props.label}</Typography>
                <Divider />
            </Box>
            <Box mx={2} mt={1}>
                {props.description.map((v, i) => (
                    <Typography variant="body2" key={i}>
                        {v}
                    </Typography>
                ))}
            </Box>
        </Box>
    );
};
