import { Box } from '@mui/material';
import type { Talent } from '@spec/Talent';
import { BackHandIcon } from '../../../Icons';
import { SubTitle } from '../../../PageTitle';
import { TouchHistory } from './History';
import { TouchLink } from './TouchLink';

export const Touch = (props: { talent: Talent }) => {
    return (
        <Box>
            <SubTitle icon={BackHandIcon} title="タッチの記録" />
            <Box my={2}>
                <TouchLink talent={props.talent} />
            </Box>
            <TouchHistory />
        </Box>
    );
};
