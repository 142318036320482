import {
    Box,
    Button,
    Checkbox,
    Dialog,
    DialogActions,
    DialogContent,
    DialogTitle,
    Divider,
    List,
    ListItem,
    ListItemButton,
    ListItemIcon,
    ListItemText,
    Typography,
} from '@mui/material';
import {
    SURVEY_UNSURE_SCORE,
    type Survey,
    type SurveyCompactResponse,
    type SurveyGroup,
    type SurveyPeriodReportResponse,
    type SurveyQuestion,
} from '@spec/Survey';
import type { TalentId } from '@spec/Talent';
import dayjs, { type Dayjs } from 'dayjs';
import { useCallback, useState } from 'react';
import { z } from 'zod';
import { isReadableSurvey } from '../../../../domains/Survey';
import { generateTsv } from '../../../../lib/Csv';
import { useStorageJson } from '../../../../lib/Storage';
import { useMeContext } from '../../../../queries/me';
import { useCurrentTimeContext } from '../../../Context';
import { ContentCopyIcon } from '../../../Icons';
import type { ResponseValue } from '../../apply/ApplyForm';
import { useSurveysContext } from '../../Context';
import { sortRows } from './ReportTable';
import type { Row } from './types';

export const CopyButton = (props: {
    group: SurveyGroup;
    questions: SurveyQuestion[];
    rows: Row[];
    lastReport: SurveyPeriodReportResponse;
    secondLastReport: SurveyPeriodReportResponse;
}) => {
    const { currentTime } = useCurrentTimeContext();
    const { grants } = useMeContext();
    const surveys = useSurveysContext().surveys.filter((v) => v.surveyGroupId === props.group.id);
    const [openDialog, setOpenDialog] = useState(false);
    const [copied, setCopied] = useState(false);

    const storageKey = `surveyCopyTarget:${props.group.id}`;
    const [copyTarget, setCopyTarget] = useStorageJson(
        storageKey,
        {
            surveyIds: [],
            lastResponse: false,
            lastDiff: false,
            secondLastResponse: false,
            secondLastDiff: false,
        },
        z.object({
            surveyIds: z.array(z.number()),
            lastResponse: z.boolean(),
            lastDiff: z.boolean(),
            secondLastResponse: z.boolean(),
            secondLastDiff: z.boolean(),
        })
    );

    const questionIdMap = buildQuestionIdMap(surveys);
    const copyReport = useCallback(() => {
        const questions = props.questions.filter((q) => {
            const surveyId = questionIdMap.get(q.id);
            if (surveyId === undefined) {
                return false;
            }
            return copyTarget.surveyIds.includes(surveyId);
        });
        const sortedRows = sortRows(props.rows, 'respondedAt', 'desc');
        return navigator.clipboard
            .writeText(
                serializeReport(
                    questions,
                    sortedRows,
                    currentTime,
                    props.lastReport,
                    props.secondLastReport,
                    copyTarget.lastResponse,
                    copyTarget.lastDiff,
                    copyTarget.secondLastResponse,
                    copyTarget.secondLastDiff
                )
            )
            .then(() => setCopied(true))
            .then(() =>
                setTimeout(() => {
                    setCopied(false);
                }, 800)
            );
    }, [
        props.questions,
        props.rows,
        props.lastReport,
        props.secondLastReport,
        currentTime,
        questionIdMap,
        copyTarget,
    ]);
    return (
        <Box>
            <Dialog open={openDialog} onClose={() => setOpenDialog(false)} maxWidth="sm" fullWidth>
                <DialogTitle>コピーする対象を選択してください</DialogTitle>
                <DialogContent>
                    <Box display="flex" gap={2}>
                        <Box flexGrow={1}>
                            <Typography>対象のサーベイ</Typography>
                            <List dense>
                                {surveys.map((survey) =>
                                    isReadableSurvey(survey, grants.survey) ? (
                                        <ListItemButton
                                            key={survey.id}
                                            onClick={() => {
                                                setCopyTarget('surveyIds', (before) => {
                                                    if (before.includes(survey.id)) {
                                                        return before.filter(
                                                            (v) => v !== survey.id
                                                        );
                                                    }
                                                    return [...before, survey.id];
                                                });
                                            }}
                                        >
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={copyTarget.surveyIds.includes(
                                                        survey.id
                                                    )}
                                                    disableRipple
                                                />
                                            </ListItemIcon>
                                            <ListItemText>{survey.name}</ListItemText>
                                        </ListItemButton>
                                    ) : (
                                        <ListItem key={survey.id}>
                                            <ListItemIcon>
                                                <Checkbox
                                                    checked={copyTarget.surveyIds.includes(
                                                        survey.id
                                                    )}
                                                    disabled={true}
                                                />
                                            </ListItemIcon>
                                            <ListItemText
                                                sx={(theme) => ({
                                                    color: theme.palette.text.disabled,
                                                })}
                                            >
                                                {survey.name}（閲覧権限がありません）
                                            </ListItemText>
                                        </ListItem>
                                    )
                                )}
                            </List>
                        </Box>
                        <Divider orientation="vertical" flexItem />
                        <Box>
                            <Typography>過去の回答</Typography>
                            <List dense>
                                <ListItemButton
                                    onClick={() =>
                                        setCopyTarget('lastResponse', (before) => {
                                            return !before;
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={copyTarget.lastResponse} />
                                    </ListItemIcon>
                                    <ListItemText>前回の回答</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() =>
                                        setCopyTarget('lastDiff', (before) => {
                                            return !before;
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={copyTarget.lastDiff} />
                                    </ListItemIcon>
                                    <ListItemText>前回と今回の差分</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() =>
                                        setCopyTarget('secondLastResponse', (before) => {
                                            return !before;
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={copyTarget.secondLastResponse} />
                                    </ListItemIcon>
                                    <ListItemText>前々回の回答</ListItemText>
                                </ListItemButton>
                                <ListItemButton
                                    onClick={() =>
                                        setCopyTarget('secondLastDiff', (before) => {
                                            return !before;
                                        })
                                    }
                                >
                                    <ListItemIcon>
                                        <Checkbox checked={copyTarget.secondLastDiff} />
                                    </ListItemIcon>
                                    <ListItemText>前々回と今回の差分</ListItemText>
                                </ListItemButton>
                            </List>
                        </Box>
                    </Box>
                </DialogContent>
                <DialogActions sx={{ px: 3, pb: 2 }}>
                    <Button
                        onClick={() => setOpenDialog(false)}
                        size="medium"
                        variant="outlined"
                        tabIndex={2}
                    >
                        キャンセル
                    </Button>
                    <Box flexGrow={1} textAlign="right">
                        <Button
                            onClick={copyReport}
                            size="medium"
                            variant="contained"
                            color="primary"
                            startIcon={<ContentCopyIcon fontSize="small" />}
                            disabled={copied || copyTarget.surveyIds.length === 0}
                            tabIndex={1}
                        >
                            {copied ? 'コピーしました' : 'コピーする'}
                        </Button>
                    </Box>
                </DialogActions>
            </Dialog>
            <Button
                variant="outlined"
                onClick={() => setOpenDialog(true)}
                startIcon={<ContentCopyIcon fontSize="small" color="primary" />}
                sx={{
                    p: 0.5,
                    px: 1.5,
                    width: '9rem',
                }}
            >
                <Box flexGrow={1}>レポートをコピー</Box>
            </Button>
        </Box>
    );
};

/**
 * Builds a map of question IDs to their corresponding survey IDs.
 *
 * @param surveys
 * @returns Map<questionId, surveyId>
 */
const buildQuestionIdMap = (surveys: Survey[]): Map<number, number> => {
    const questionIdMap = new Map<number, number>();
    for (const survey of surveys) {
        for (const question of survey.questions) {
            questionIdMap.set(question.id, survey.id);
        }
    }
    return questionIdMap;
};

const serializeReport = (
    questions: SurveyQuestion[],
    rows: Row[],
    currentTime: Date,
    lastReport: SurveyPeriodReportResponse,
    secondLastReport: SurveyPeriodReportResponse,
    copyLastResponse: boolean,
    copyLastDiff: boolean,
    copySecondLastResponse: boolean,
    copySecondLastDiff: boolean
): string => {
    const questionTitles = questions.map((q) => q.shortTitle);
    const headers = [
        '回答日時',
        '社員番号',
        '氏名',
        '所属会社',
        '部署1',
        '部署2',
        '部署3',
        '部署4',
        '部署5',
        'ざっくり所属',
        '所属',
        '役職',
        '雇用形態',
        'グレード',
        '入社日',
        '入社歴（n年目）',
        '退職日',
        '入社区分',
        ...questionTitles,
    ];

    if (copyLastResponse) {
        headers.push('回答日時', ...questionTitles);
    }
    if (copyLastDiff) {
        headers.push(...questionTitles);
    }
    if (copySecondLastResponse) {
        headers.push('回答日時', ...questionTitles);
    }
    if (copySecondLastDiff) {
        headers.push(...questionTitles);
    }

    const contents = rows.map((v) => {
        const row = [
            formatRespondedAt(v.respondedAt),
            v.employeeCode,
            v.name,
            v.corporateName,
            v.teams[1]?.name ?? '',
            v.teams[2]?.name ?? '',
            v.teams[3]?.name ?? '',
            v.teams[4]?.name ?? '',
            v.teams[5]?.name ?? '',
            v.baseTeamName,
            v.teamName,
            v.position,
            v.employmentStatus,
            v.grade,
            v.joinedAt.format('YYYY/MM/DD'),
            dayjs(currentTime).diff(v.joinedAt, 'years') + 1,
            v.leftAt === null ? '' : v.leftAt.format('YYYY/MM/DD'),
            v.isNewGraduate ? '新卒' : '中途',
            ...questions.map((q) => serializeResponseValue(v[q.id])),
        ];

        if (copyLastResponse) {
            const respondedAt = lastReport.responses.find(
                (r) => r.talentId === v.talentId
            )?.respondedAt;
            row.push(
                formatRespondedAt(respondedAt),
                ...questions.map((q) =>
                    serializeResponseValue(getLastResponse(lastReport, v.talentId, q.id))
                )
            );
        }
        if (copyLastDiff) {
            row.push(
                ...questions.map((q) => {
                    const current = serializeResponseValue(v[q.id]);
                    const past = getLastResponse(lastReport, v.talentId, q.id) ?? '';
                    if (typeof current === 'number' && typeof past === 'number') {
                        return current - past;
                    }
                    return '';
                })
            );
        }
        if (copySecondLastResponse) {
            const respondedAt = secondLastReport.responses.find(
                (r) => r.talentId === v.talentId
            )?.respondedAt;
            row.push(
                formatRespondedAt(respondedAt),
                ...questions.map((q) =>
                    serializeResponseValue(getLastResponse(secondLastReport, v.talentId, q.id))
                )
            );
        }
        if (copySecondLastDiff) {
            row.push(
                ...questions.map((q) => {
                    const current = serializeResponseValue(v[q.id]);
                    const past = getLastResponse(secondLastReport, v.talentId, q.id) ?? '';
                    if (typeof current === 'number' && typeof past === 'number') {
                        return current - past;
                    }
                    return '';
                })
            );
        }

        return row;
    });

    return generateTsv(headers, contents);
};

const serializeResponseValue = (res: ResponseValue | null): string | number => {
    if (typeof res === 'boolean') {
        return res ? 'x' : '';
    }
    if (Array.isArray(res)) {
        return res.join(', ');
    }
    if (res === SURVEY_UNSURE_SCORE) {
        return '-';
    }
    return res ?? '';
};

const getLastResponse = (
    report: SurveyPeriodReportResponse,
    talentId: TalentId,
    questionId: number
): ResponseValue | null => {
    const res = report.responses
        .find((v) => v.talentId === talentId)
        ?.responses.find((v) => v.questionId === questionId);
    if (res === undefined) {
        return null;
    }
    return getResponseValue(res);
};

const getResponseValue = (v: SurveyCompactResponse): ResponseValue => {
    if ('score' in v) {
        return v.score;
    }
    if ('message' in v) {
        return v.message;
    }
    if ('checked' in v) {
        return v.checked;
    }
    return v.options;
};

const formatRespondedAt = (respondedAt: Date | Dayjs | null | undefined): string => {
    if (respondedAt === null || respondedAt === undefined) {
        return '-';
    }
    return dayjs(respondedAt).format('YYYY/MM/DD HH:mm:ss');
};
