import { Box, Card, CardContent, Container, Typography } from '@mui/material';
import { useParams } from 'react-router-dom';
import { findActivePeriod } from '../../../domains/Survey';
import { findById } from '../../../lib/ArrayUtils';
import { useIsAppliedSurveyGroup } from '../../../queries/survey';
import { useCurrentTimeContext } from '../../Context';
import { Markdown } from '../../Markdown';
import { NoItems } from '../../NoItems';
import { WaitLoading } from '../../WaitLoading';
import { useSurveysContext } from '../Context';
import { ApplyForm } from './ApplyForm';

export const ApplySurvey: React.FC = () => {
    const { groupId } = useParams();
    const { surveyGroups } = useSurveysContext();
    const surveyGroupId = parseInt(groupId ?? '', 10);
    const currentGroup = findById(surveyGroupId, surveyGroups);
    const isApplied = useIsAppliedSurveyGroup(surveyGroupId);
    const { currentDayjs } = useCurrentTimeContext();
    const period = findActivePeriod(currentGroup, currentDayjs);
    if (period === null) {
        return <NoItems mt={8}>現在「{currentGroup.name}」は実施されていません</NoItems>;
    }
    return (
        <Box>
            <Typography mb={1} textAlign="center" variant="h6">
                {currentGroup.name} {period.name}
            </Typography>
            <Container maxWidth="md">
                <Card>
                    <CardContent>
                        <Markdown source={currentGroup.description} variant="body2" />
                    </CardContent>
                </Card>
            </Container>
            <WaitLoading size="medium" waitFor={[isApplied]}>
                {isApplied.data === true && (
                    <Box m={4}>
                        <Typography textAlign="center" color="primary">
                            回答済みのサーベイです
                        </Typography>
                    </Box>
                )}
                {isApplied.data === false && <ApplyForm group={currentGroup} period={period} />}
            </WaitLoading>
        </Box>
    );
};
