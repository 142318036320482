import { Box, Card, CardContent } from '@mui/material';
import { type ApplicableSurvey } from '@spec/Survey';
import { useApplicableSurveys } from '../../../queries/survey';
import { ActionButton, ActionContainer } from '../../ActionButtons';
import { FlexBox } from '../../FlexBox';
import { ReplyIcon } from '../../Icons';
import { Markdown } from '../../Markdown';
import { NoItems } from '../../NoItems';
import { SubTitle } from '../../PageTitle';
import { RouterLink } from '../../RouterLink';
import { WaitQuery } from '../../WaitLoading';
import { getApplySurveyUrl } from '../urls';

export const ActiveSurveys: React.FC = () => {
    const applicableSurveys = useApplicableSurveys();
    return (
        <Box>
            <SubTitle title="未回答のサーベイ" />
            <WaitQuery query={applicableSurveys}>
                {({ data }) => (
                    <>
                        {data.length === 0 && (
                            <NoItems mt={4}>未回答のサーベイはありません。</NoItems>
                        )}
                        {data.map((v) => (
                            <ActiveSurveyGroup key={v.group.id} survey={v} />
                        ))}
                    </>
                )}
            </WaitQuery>
        </Box>
    );
};

const ActiveSurveyGroup = (props: { survey: ApplicableSurvey }) => {
    const { group, period } = props.survey;
    const applyUrl = getApplySurveyUrl(group.id);
    const title = [group.name, period.name].join(' ');
    return (
        <Box mt={2}>
            <Card>
                <CardContent>
                    <FlexBox alignItems="baseline" gap={1}>
                        <RouterLink variant="h6" to={applyUrl}>
                            {title}
                        </RouterLink>
                    </FlexBox>
                    <Markdown source={group.description} variant="body2" />
                    <ActionContainer>
                        <ActionButton startIcon={<ReplyIcon />} to={applyUrl}>
                            {group.name}に回答する
                        </ActionButton>
                    </ActionContainer>
                </CardContent>
            </Card>
        </Box>
    );
};
