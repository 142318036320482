import React from 'react';
import { Route } from 'react-router-dom';
import { RoutingPattern } from '../../Routes';
import { Index } from './';
import { Layout } from './Layout';
import { Preview } from './admin/Preview';
import { SurveyAdmin } from './admin/list';
import { Operators } from './admin/operators/Operators';
import { SurveyPeriods } from './admin/periods';
import { Permissions } from './admin/permissions';
import { Admin } from './admin/survey';
import { ApplySurvey } from './apply';
import { Report } from './report';

export const SurveyRoutes = (): React.ReactNode => (
    <Route path={RoutingPattern.survey} element={<Layout />}>
        <Route index element={<Index />} />
        <Route path=":groupId" element={<ApplySurvey />} />
        <Route path="admin">
            <Route index element={<SurveyAdmin />} />
            <Route path="groups/:groupId">
                <Route path="permissions" element={<Operators />} />
                <Route path="preview" element={<Preview />} />
            </Route>
            <Route path="surveys">
                <Route path=":surveyId">
                    <Route index element={<Admin />} />
                    <Route path="permissions" element={<Permissions />} />
                </Route>
            </Route>
            <Route path="periods/:groupId" element={<SurveyPeriods />} />
        </Route>
        <Route path="report/:groupId" element={<Report />} />
    </Route>
);
